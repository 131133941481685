import { createSlice } from '@reduxjs/toolkit';

const initialState = { 
    data:[],
    selectedContact:{}
};

const setContacts = (state, data) => {
    state.data = data;
}

const addNewContact = (state, data) => {
    state.data.push(data);
}

const setSelectContact = (state, data) => {
    state.selectedContact = data;
}

export const contactosSlice = createSlice({
    name: 'contactos',
    initialState,
    reducers: {
        SetContacts: (state, action) => setContacts(state, action.payload),
        AddContact: (state, action) => addNewContact(state, action.payload),
        SelectContact: (state, action) => setSelectContact(state, action.payload)
    }
});

export const { SetContacts, AddContact, SelectContact } = contactosSlice.actions;

export default contactosSlice.reducer;