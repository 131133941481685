
import React, { useState, useEffect } from "react";
import InfiniteScroll from 'react-infinite-scroll-component';

// importar scss
import "./styles/ExpedientesNuevo.scss";

// Iconos
import AgregarBlanco from "../Img/Iconos/AgregarBlanco.svg";
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import ButtonAntd from "../componentes/GeneralComponents/ButtonAntd";

//Libreria hormiga
import { Input, Empty, } from "antd";
import AddExpediente from "../componentes/Expedientes/ModalAddExpediente";
import ModalEx from "../componentes/GeneralComponents/ModalDetalle";
import { UseModal } from "../hooks/UseModal";
import { expedientes_partes_request, expedientes_request, getContactosSinExpedientes_request } from "../services/expediente_service";
import { Loader } from "../componentes/GeneralComponents/Loader";

import ExpedienteTablaCard from '../componentes/NuevoExpediente/ExpedienteTablaCard';
import { Content } from "antd/es/layout/layout";
import NavbarNuevo from "../componentes/GeneralComponents/Navbar_Nuevo";
import ExpedientesCard from "../componentes/NuevoExpediente/ExpedientesCard";

import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import { Row, Skeleton, List, Divider } from "antd";
import { CircularProgress } from "@mui/material";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

// TODO refactor
// TODO usar Query
export default function Expedientes() {
  let data = JSON.parse(sessionStorage.getItem("infoUser"));
  // Validacion de Inputs
  const expresiones = {
    Letras: /^[a-zA-ZÀ-ÿ\s]{1,40}$/, // Letras y espacios, pueden llevar acentos.
    password: /^.{4,12}$/, // 4 a 12 digitos.
    correo: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
    telefono: /^\d{1,10}$/, // 7 a 14 numeros.
    ApellPaterno: /^[a-zA-ZÀ-ÿ\s]{1,40}$/, // Letras y espacios, pueden llevar acentos.
    ApellMaterno: /^[a-zA-ZÀ-ÿ\s]{1,40}$/, // Letras y espacios, pueden llevar acentos.
    // nacimiento: getEdad, // Letras y espacios, pueden llevar acentos.
  };

  // ******* USE STATES *******
  // Modales
  const [isOpenModalEx, openModalEx, CloseModalEx] = UseModal(false);
  const [isOpenModalEx2, openModalEx2, CloseModalEx2] = UseModal(false);

  const [expe, setExpe] = useState([]);
  const [ScreenDetail, setScreenDetail] = useState(false);
  const [detalleFicha, setDetalleFicha] = useState("");
  const [loader, setLoader] = useState(false);
  const [loaderDetalle, setLoaderDetalle] = useState(false);
  const [expedientes, setExpedientes] = useState([]);
  const [expedientesFilter, setExpedientesFilter] = useState([]);
  const [contactosSinExpedientes, setContactosSinExpedientes] = useState([]);
  const [contactosSinExpedientesFilter, setContactosSinExpedientesFilter] =
    useState([]);
  const [userImage, setUserImage] = useState("");
  const [idContacto, setIdContacto] = useState("");
  const [datos, setDatos] = useState({ id: "", nombre: "", imagen: "" });

  const [TotalPaginas, setTotalPaginas] = useState(0);
  const [paginaCargada, setpaginaCargada] = useState(0);

  // MANEJA DE MENSAJES
  const [open, setOpen] = useState(false);
  const [typeMessage, setTypeMessage] = useState("");
  const [message, setMessage] = useState("");

  const [comboTipoAntecedente, setComboTipoAntecedente] = useState("true");

  // Tamaños de modal
  const styleDet = {
    height: "clac(100vh - 52px)",
    width: "clac(100vw - 52px)",
    margin: "0 2%",
  };

  // ******* FUNCIONEs *******

  const get_expedientes = async () => {
    // this.setState({loading:true})
    setLoader(true);
    let response = await expedientes_request();
    if (response["ok"]) {
      // setTotalPaginate(response.data.Paginas);
      if (document.domain === "localhost") {
        console.log(`TOTAL DE REGISTROS: ${response.data.length}`);
      }
      // console.log(response.data.length);
      // console.log(response.data.Expedientes.length);
      // console.log(response.data);
      // localStorage.setItem("Expediente", JSON.stringify(response.data));
      setExpedientes(response.data.slice().sort((a, b) => a.Nombre.localeCompare(b.Nombre)));
      setExpedientesFilter(response.data.slice().sort((a, b) => a.Nombre.localeCompare(b.Nombre)));
      // setContactosSinExpedientes(response.contacts);
      // setContactosSinExpedientesFilter(response.contacts);
    } else {
      setOpen(true);
      setTypeMessage("error");
      setMessage(response.mensaje);
      setExpedientes([]);
      setExpedientesFilter([]);
    }
    setLoader(false);
    return;
  };

  const get_expedientes_partes = async () => {
    // setpaginaCargada(0);
    // this.setState({loading:true})
    setLoader(true);
    let data = new FormData();
    data.append("id", "");
    let response = await expedientes_partes_request(data);
    if (response["ok"]) {
      setTotalPaginas(parseInt(response.data.Paginas));
      setpaginaCargada(1);
      // setExpedientes(response.data.Expedientes.slice().sort((a, b) => a.Nombre.localeCompare(b.Nombre)));
      // setExpedientesFilter(response.data.Expedientes.slice().sort((a, b) => a.Nombre.localeCompare(b.Nombre)));
      setExpedientes(response.data.Expedientes.slice().sort((a, b) => new Date(`${b.Date.substring(6, 10)}-${b.Date.substring(3, 5)}-${b.Date.substring(0, 2)}`) - new Date(`${a.Date.substring(6, 10)}-${a.Date.substring(3, 5)}-${a.Date.substring(0, 2)}`)));
      setExpedientesFilter(response.data.Expedientes.slice().sort((a, b) => new Date(`${b.Date.substring(6, 10)}-${b.Date.substring(3, 5)}-${b.Date.substring(0, 2)}`) - new Date(`${a.Date.substring(6, 10)}-${a.Date.substring(3, 5)}-${a.Date.substring(0, 2)}`)));

      if (parseInt(response.data.Paginas) > 1) {
        get_more_expedientes(response.data.Expedientes[response.data.Expedientes.length - 1].idContacto, parseInt(response.data.Paginas), 2, response.data.Expedientes);
      }
    } else {
      setOpen(true);
      setTypeMessage("error");
      setMessage(response.mensaje);
      setExpedientes([]);
      setExpedientesFilter([]);
    }
    setLoader(false);
    return;
  };

  const get_more_expedientes = async (lastId, total, pagSig, expedientes) => {
    if (document.domain === "localhost") {
      console.log(total);
      console.log(pagSig);
      console.log(lastId);
    }
    // this.setState({loading:true})
    // setLoader(true);
    let id = '';
    // console.log(`TotalPaginate: ${total}`);
    let expedientesTemp = expedientes;

    let data = new FormData();
    data.append("id", !!id ? id : lastId);
    // return;
    let response = await expedientes_partes_request(data);
    // console.log(response);
    if (response["ok"]) {
      setpaginaCargada(pagSig);
      if (document.domain === "localhost") {
        console.log(response.data.Expedientes.length);
      }
      // if (index == 1) {
      //   expedientesTemp = response.data.Expedientes;
      // } else {
      //   expedientesTemp.concat(response.data.Expedientes);
      // }
      const combined = [...expedientesTemp, ...response.data.Expedientes];
      // expedientesTemp.push(response.data.Expedientes);
      setTimeout(async () => { }, "5000");
      if (total !== pagSig) {
        // console.log(total);
        // console.log(pagSig);
        get_more_expedientes(response.data.LastId, total, pagSig + 1, combined);
      } else {
        if (document.domain === "localhost") {
          console.log(combined.length);
        }
        // localStorage.setItem("ExpedienteParte", JSON.stringify(combined));
        // setExpedientes(combined.slice().sort((a, b) => a.Nombre.localeCompare(b.Nombre)));
        // setExpedientesFilter(combined.slice().sort((a, b) => a.Nombre.localeCompare(b.Nombre)));
        setExpedientes(combined.slice().sort((a, b) => new Date(`${b.Date.substring(6, 10)}-${b.Date.substring(3, 5)}-${b.Date.substring(0, 2)}`) - new Date(`${a.Date.substring(6, 10)}-${a.Date.substring(3, 5)}-${a.Date.substring(0, 2)}`)));
        setExpedientesFilter(combined.slice().sort((a, b) => new Date(`${b.Date.substring(6, 10)}-${b.Date.substring(3, 5)}-${b.Date.substring(0, 2)}`) - new Date(`${a.Date.substring(6, 10)}-${a.Date.substring(3, 5)}-${a.Date.substring(0, 2)}`)));
      }
      // expedientes.push(response.data.Expedientes);
      // expedientesFilter.push(response.data.Expedientes);
      // console.log(expedientesTemp);
      // setultimoId(response.data.Expedientes[response.data.Expedientes.length - 1].idContacto)
      // ultimoId = response.data.Expedientes[response.data.Expedientes.length - 1].idContacto;
    } else {
      setOpen(true);
      setTypeMessage("error");
      setMessage(response.mensaje);
    }




    // for (let index = 1; index < total; index++) {

    //   // console.log(`ultimo ID: ${id.variable}`);
    //   let data = new FormData();
    //   data.append("id", !!id ? id : lastId);
    //   // return;
    //   let response = await expedientes_partes_request(data);
    //   if (response["ok"]) {

    //     // console.log(response.data.Expedientes);
    //     // if (index == 1) {
    //     //   expedientesTemp = response.data.Expedientes;
    //     // } else {
    //     //   expedientesTemp.concat(response.data.Expedientes);
    //     // }
    //     expedientesTemp.concat(response.data.Expedientes);
    //     // expedientes.push(response.data.Expedientes);
    //     // expedientesFilter.push(response.data.Expedientes);
    //     // console.log(expedientesTemp);
    //     // setultimoId(response.data.Expedientes[response.data.Expedientes.length - 1].idContacto)
    //     id = ({ ...id, variable: response.data.Expedientes[response.data.Expedientes.length - 1].idContacto })
    //     // ultimoId = response.data.Expedientes[response.data.Expedientes.length - 1].idContacto;
    //   } else {
    //     setOpen(true);
    //     setTypeMessage("error");
    //     setMessage(response.mensaje);
    //   }
    //   setTimeout(async () => { }, "5000");

    // }

    setLoader(false);
    return;
  };

  const get_contactosSinExpedientes = async () => {
    // this.setState({loading:true})
    let response = await getContactosSinExpedientes_request();
    if (response["ok"]) {
      // console.log(response.data);
      if (response.data.length !== 0) {
        let contactos = [];
        response.data.forEach(element => {
          if (element.tipo !== "Asistente") {
            contactos.push(element);
          }
        });
        setContactosSinExpedientes(contactos);
        setContactosSinExpedientesFilter(contactos);
      } else {
        setContactosSinExpedientes([]);
        setContactosSinExpedientesFilter([]);
      }

      // console.log(contactosSinExpedientes);
    } else {
      setContactosSinExpedientes([]);
    }
    // console.log(response);
    return;
  };

  function filterListContacts(text) {
    // console.log(text.target.value);
    // console.log(solicitudesSearch);
    let filter = [];
    contactosSinExpedientesFilter.forEach((contacto) => {
      // console.log(solicitud.Usuario.toLowerCase(text));
      if (
        contacto.nombre.toLowerCase().indexOf(text.target.value.toLowerCase()) >
        -1
      ) {
        filter.push(contacto);
        // console.log(solicitud);
      }
    });
    // console.log(filter);

    setContactosSinExpedientes(filter);
  }

  function filterList(text) {
    // console.log(text.target.value);
    // let palabra = text.target.value;
    // console.log(text.target.value);
    // console.log(solicitudesSearch);
    var chars = {
      "á": "a", "é": "e", "í": "i", "ó": "o", "ú": "u",
      "à": "a", "è": "e", "ì": "i", "ò": "o", "ù": "u", "ñ": "n",
      "Á": "A", "É": "E", "Í": "I", "Ó": "O", "Ú": "U",
      "À": "A", "È": "E", "Ì": "I", "Ò": "O", "Ù": "U", "Ñ": "N"
    }
    var expr = /[áàéèíìóòúùñ]/ig;
    let filter = [];
    expedientesFilter.forEach((expediente) => {
      // console.log(expediente);
      if (
        expediente.Nombre.replace(expr, function (e) { return chars[e] }).toLowerCase().indexOf(
          text.target.value.replace(expr, function (e) { return chars[e] }).toLowerCase()
        ) > -1
      ) {
        filter.push(expediente);
        console.log(expediente);
      }
    });

    setExpedientes(filter);
  }

  // ******* USE EFFECT *******

  useEffect(() => {
    if (document.domain === "ygia.app") {
      // get_expedientes();
      get_expedientes_partes();
    } else {
      get_expedientes();
      // get_expedientes_partes();
    }
    // diferencia();
    get_contactosSinExpedientes();
    // console.log(window.screen.height);
  }, []);

  return (
    <>
      <Stack>
        <NavbarNuevo
          modulo={"Expedientes"}
          mensajesError={{
            setOpen: setOpen,
            setTypeMessage: setTypeMessage,
            setMessage: setMessage,
          }}
          searchBar={
            <div className="CebezeraExpedientes">
              <div className="Encabesado-Expedientes" style={{ alignItems: "center" }}>
                <div style={{ width: "50%" }}>
                  <b>Expediente</b>
                </div>
                {(
                  data.Tipo === 1 && (<ButtonAntd
                    tipo={1}
                    style={{ width: "100px" }}
                    onClick={() => {
                      openModalEx(true);
                    }}
                    children={
                      <Row style={{ width: "100%", gap: ".5rem", color: "white", justifyContent: "space-between", alignItems: "center" }}>
                        Nuevo
                        <img style={{ width: "12px" }} src={AgregarBlanco} alt="" />
                      </Row>
                    }
                  />)
                )}

              </div>
              <div style={{ margin: "1% 0", display: "flex", justifyContent: "space-between" }}>

                <Input
                  prefix={<SearchIcon style={{ color: "#d7d7d7", width: "18px" }} />}
                  style={{ width: "50%" }}
                  placeholder="Paciente"
                  type="text"
                  onChange={filterList}
                />

                <div style={{ display: "flex" }}>
                  <b>Página: {paginaCargada}/{TotalPaginas}</b>
                  {paginaCargada !== TotalPaginas && (<CircularProgress size={25} sx={{ color: "#148f9f" }} />)}
                </div>
              </div>
            </div>
          } />

        <Content
          className="site-layout"
          style={{
            // marginTop: "100px",
            padding: '0 3%',
            // height: "calc(100vh - 52px)",
            position: "relative",
            width: "100%",
            overflow: 'initial',
          }}
        >
          {loader ? (
            <Loader />
          ) : (
            <>
              <ExpedientesCard expedientes={expedientes} />
              {expedientes.length === 0 && (
                <Empty description={<span style={{ color: "black" }}>Sin expedientes</span>} />
              )}
            </>
          )}
        </Content>
        <ModalEx isOpen={isOpenModalEx} closeModal={CloseModalEx} styleDet={styleDet} optionClose={true}>
          <div className="ExpediTop">
            Nuevo expediente <CloseIcon style={{ cursor: "pointer", fontSize: 20 }} onClick={CloseModalEx} />
          </div>
          <div className="ExpediContAddNew">
            <p>Contacto</p>
            <Input
              placeholder="Buscar..."
              type="search"
              style={{ width: "100%", marginBottom: "10px" }}
              onChange={filterListContacts}
            />

            <div
              className="contCardModal"
              style={{ borderTop: "1px solid #d5d5d5" }}
            >
              {/* <AddLista lista={contactosSinExpedientes} openModal={openModalEx2} CloseModal={CloseModalEx} setId={setIdContacto}/> */}
              <AddExpediente
                contactos={contactosSinExpedientes}
                isOpen={openModalEx2}
                closeModal={CloseModalEx}
                setIdContacto={setIdContacto}
              />
            </div>
          </div>
        </ModalEx>
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={() => setOpen(false)}
        >
          <Alert
            onClose={() => setOpen(false)}
            severity={typeMessage}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
}
