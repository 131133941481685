import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

const initialState = {
    IdHorario: 0,
    HoraInicioLunes: "",
    HoraFinLunes: "",
    MO: 0,
    HoraInicioMartes: "",
    HoraFinMartes: "",
    TU: 0,
    HoraInicioMiercoles: "",
    HoraFinMiercoles: "",
    WE: 0,
    HoraInicioJueves: "",
    HoraFinJueves: "",
    TH: 0,
    HoraInicioViernes: "",
    HoraFinViernes: "",
    FR: 0,
    HoraInicioSabado: "",
    HoraFinSabado: "",
    SA: 0,
    HoraInicioDomingo: "",
    HoraFinDomingo: "",
    SU: 0,
    TiempoCita: 0,
    TipoHorario: 0,
    horariosConsulta: []
};

const setHorariosData = (state, data) =>{
    console.log(data)
    state.IdHorario = data.IdHorario;
    state.HoraInicioLunes = data.HoraInicioLunes;
    state.HoraFinLunes = data.HoraFinLunes;
    state.MO = data.MO;
    state.HoraInicioMartes = data.HoraInicioMartes;
    state.HoraFinMartes = data.HoraFinMartes;
    state.TU = data.TU;
    state.HoraInicioMiercoles = data.HoraInicioMiercoles;
    state.HoraFinMiercoles = data.HoraFinMiercoles;
    state.WE = data.WE;
    state.HoraInicioJueves = data.HoraInicioJueves;
    state.HoraFinJueves = data.HoraFinJueves;
    state.TH = data.TH;
    state.HoraInicioViernes = data.HoraInicioViernes;
    state.HoraFinViernes = data.HoraFinViernes;
    state.FR = data.FR;
    state.HoraInicioSabado = data.HoraInicioSabado;
    state.HoraFinSabado = data.HoraFinSabado;
    state.SA = data.SA;
    state.HoraInicioDomingo = data.HoraInicioDomingo;
    state.HoraFinDomingo = data.HoraFinDomingo;
    state.SU = data.SU;
    state.TiempoCita = data.TiempoCita;
    state.TipoHorario = data.TipoHorario;

    const horarios = processHorarios(data);

    state.horariosConsulta = horarios;
}

const processHorarios = (horarios) => {
    console.log(horarios)
    let HorariosTrabajo = [[],[],[],[],[],[],[]];

    if(horarios.SU){
        HorariosTrabajo[0] = getHorariosDay(horarios.HoraInicioDomingo, horarios.HoraFinDomingo, horarios.TiempoCita);
    }
    if(horarios.MO){
        HorariosTrabajo[1] = getHorariosDay(horarios.HoraInicioLunes, horarios.HoraFinLunes, horarios.TiempoCita);
    }
    if(horarios.TU){
        HorariosTrabajo[2] = getHorariosDay(horarios.HoraInicioMartes, horarios.HoraFinMartes, horarios.TiempoCita);
    }
    if(horarios.WE){
        HorariosTrabajo[3] = getHorariosDay(horarios.HoraInicioMiercoles, horarios.HoraFinMiercoles, horarios.TiempoCita);
    }
    if(horarios.TH){
        HorariosTrabajo[4] = getHorariosDay(horarios.HoraInicioJueves, horarios.HoraFinJueves, horarios.TiempoCita);
    }
    if(horarios.FR){
        HorariosTrabajo[5] = getHorariosDay(horarios.HoraInicioViernes, horarios.HoraFinViernes, horarios.TiempoCita);
    }
    if(horarios.SA){
        HorariosTrabajo[6] = getHorariosDay(horarios.HoraInicioSabado, horarios.HoraFinSabado, horarios.TiempoCita);
    }

    return HorariosTrabajo;
}

const getHorariosDay = (start, end, intervalMinutes) => {
    let schedule = [];

    const startdate = dayjs(start, "hh:mm A");
    const enddate = dayjs(end, 'hh:mm A');
    
    let newdate = startdate;
    while(newdate<enddate){
        schedule.push(newdate.format('h:mm A').replace('AM', 'a.m').replace('PM', 'p.m'));
        newdate = newdate.add(intervalMinutes, 'minute');
    }

    return schedule;
}

export const horariosAtencion = createSlice({
    name: 'horarios',
    initialState,
    reducers: {
        SetHorario: (state, action) => setHorariosData(state, action.payload),
    }
});

export const { SetHorario } = horariosAtencion.actions;

export default horariosAtencion.reducer;