import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    usuario: {
        id: "",
        Prefijo: null,
        Nombre: "",
        ApellidoPaterno: "",
        ApellidoMaterno: "",
        NombreComercial: "",
        TelefonoOficina: "",
        Direccion: "",
        Celular: "",
        Cel: "",
        Email: "",
        Correo: "",
        Nacimiento: "",
        Localidad: null,
        Estado: null,
        Universidad: null,
        Sexo: null,
        TelefonoEmergencia: "",
        Tipo: null,
        Imagen_Url: "",
        Status: null,
        Paquete: "",
        IdPaquete: "",
        IdAlmacenamiento: null,
        PaqueteStatus: null,
        FechaPaquete: "",
        fecha: "",
    },
    profesiones: []
};

export const userSlice = createSlice({
    name: "infoUser",
    initialState,
    reducers: {

        infoUser: (state, action) => {
            const {
                Usuario,
                Profesiones,
            } = action.payload;
            state.usuario = Usuario;
            state.profesiones = Profesiones;
        },
    }
})


export const { infoUser } = userSlice.actions;

export default userSlice.reducer;