import React, { useEffect, useState } from 'react';
import { Row, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import LoaderModals from "../../GeneralComponents/Loader_modals";
import Iconos from '../../IconosDeSitio';
import InputAntd from '../../GeneralComponents/InputAntd';
import SelectAntd from '../../GeneralComponents/SelectAntd';
import TextAreaAntd from '../../GeneralComponents/TextAreaAntd';

import Expresiones from '../../../utils/expresiones';
import { showSnackbar } from '../../../redux/snackbarSlice';
import { HideModal, ModalScreen } from '../../../redux/appSlice';

import { contactDetail_request, editContact_request } from '../../../services/contactos_services';
import useContactosData from '../../../hooks/Query/useContactosData';
import useCitasData from '../../../hooks/Query/useCitasData';

const DetallesContactoContent = () => {
    const [loaderDetalle, setLoaderDetalle] = useState(true);
    const [ImagenContacto, setImagenContacto] = useState({
        name: "",
        url: "",
        file: "",
        size: 0,
        formato: "",
      });
    const [infocontacto, setinfocontacto] = useState("");
    const [YgiaUser, setYgiaUser] = useState('');
    const [Tipocontacto, setTipocontacto] = useState(null);
    const [Nombre, setNombre] = useState("");
    const [ApellidoP, setApellidoP] = useState("");
    const [ApellidoM, setApellidoM] = useState("");
    const [Celular, setCelular] = useState("");
    const [Correo, setCorreo] = useState("");
    const [NotaContacto, setNotaContacto] = useState("");
    const [MensajeNombre, setMensajeNombre] = useState("true");
    const [MensajeApellidoP, setMensajeApellidoP] = useState("true");
    const [MensajeApellidoM, setMensajeApellidoM] = useState("true");
    const [MensajeCelular, setMensajeCelular] = useState("true");
    const [MensajeCorreo, setMensajeCorreo] = useState("true");

    const dispatch = useDispatch();
    const { selectedContact } = useSelector((state)=>state.contacts);
    const Contactos = useContactosData();
    const Citas = useCitasData();

    useEffect(()=>{
        const getContactData = async() => {
            let response = await contactDetail_request(selectedContact.id);
            
            if (response.ok) {
                setYgiaUser(response.data.YgiaUser);
                setTipocontacto(response.data.Type.toString());
                setNombre(response.data.Nombre);
                setApellidoP(response.data.ApellidoPaterno);
                setApellidoM(response.data.ApellidoMaterno);
                setCelular(response.data.Celular);
                setCorreo(response.data.Email);
                setNotaContacto(response.data.Nota);
                setImagenContacto({
                    name: "",
                    url: `https://${response.data.Imagen_url}`,
                    file: "",
                    size: 0,
                    formato: "",
                });
                setLoaderDetalle(false);
            } else {
                dispatch(showSnackbar({
                    show: true,
                    text: response.mensaje,
                    type: 'error'
                }));
                dispatch(HideModal(ModalScreen.DETALLE_CONTACTO));
            }
        }

        getContactData();
    }, []);

    const editContact = async() => {
        setLoaderDetalle(true);
    
        if (
          !!Tipocontacto &&
          !!Nombre &&
          MensajeNombre === "true" &&
          !!ApellidoP &&
          MensajeApellidoP === "true" &&
          MensajeApellidoM === "true" &&
          MensajeCelular === "true" &&
          MensajeCorreo === "true"
        ) {
          let data = new FormData();
          data.append("Nombre", Nombre);
          data.append("ApellidoPaterno", ApellidoP);
          data.append("ApellidoMaterno", !!ApellidoM ? ApellidoM : "");
          data.append("Celular", !!Celular ? Celular : "");
          data.append("Email", !!Correo ? Correo : "");
          data.append("Tipo", Tipocontacto === "1" ? "Médico" : "Paciente");
          data.append("Nota", NotaContacto);
          if (!!ImagenContacto.name) {
            data.append("Imagen", ImagenContacto.file, ImagenContacto.name);
          }
    
          let response = await editContact_request(data, selectedContact.id);
          
          if (response.ok) {
            Citas.refetch();
            dispatch(showSnackbar({
                show: true,
                text: 'Contacto actualizado',
                type: 'success'
            }));
            dispatch(HideModal(ModalScreen.DETALLE_CONTACTO));
            
            Contactos.refetch();
          } else {
            dispatch(showSnackbar({
                show: true,
                text: response.mensaje,
                type: 'error'
            }));
            setLoaderDetalle(false);
          }
        } else {
          if (!!!Nombre) {
            setMensajeNombre("false");
          }
    
          if (!!!ApellidoP) {
            setMensajeApellidoP("false");
          }

          dispatch(showSnackbar({
            show: true,
            text: 'Faltan campos por completar',
            type: 'warning'
          }));
          setLoaderDetalle(false);
        }
      }

    return(
        <>

          <div>
            {loaderDetalle ? (
              <LoaderModals className={"ModalConsultasStyle"} />
            ) : (
              <div className="ModalConsultasStyle">
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Row>
                    <div>
                      <label htmlFor="imagenEditContacto">
                        <img
                          className="imagencont"
                          src={
                            !!ImagenContacto.url
                              ? ImagenContacto.url
                              : Iconos[19]
                          }
                        />
                      </label>
                      {YgiaUser === "Sin Ygia" && (
                        <input
                          type="file"
                          id="imagenEditContacto"
                          accept="/image/x-PNG,image/jpeg"
                          style={{ display: "none" }}
                        />
                      )}
                    </div>
                  </Row>
                </div>
                <form>
                  <p
                    style={{
                      textAlign: "center",
                      color: "rgb(20, 143, 159)",
                      fontWeight: "500",
                    }}
                  >
                    {"Usuario " + YgiaUser}
                  </p>
                  <div>
                    {Tipocontacto === "4" ? (
                      <InputAntd
                        label="Tipo de contacto:"
                        requiredICon={true}
                        className="inputblue"
                        value="Asistente"
                        style={{ margin: "1% 0" }}
                        placeholder="Tipo de contacto"
                        type="text"
                        vacio={true}
                        readOnly={true}
                      />
                    ) : (
                      <SelectAntd
                        label="Tipo de contacto:"
                        requiredICon={true}
                        value={Tipocontacto}
                        style={{ width: "100%", margin: "1% 0" }}
                        placeholder="Tipo de contacto"
                        options={[
                          { value: "1", label: "Médico" },
                          { value: "3", label: "Paciente" },
                        ]}
                        setStateValue={setTipocontacto}
                        disabled={true}
                      />
                    )}
                    <InputAntd
                      label="Nombre:"
                      requiredICon={true}
                      className="inputblue"
                      value={Nombre}
                      setStateValue={setNombre}
                      style={{ margin: "1% 0" }}
                      placeholder="Escribe tu nombre"
                      setStateError={setMensajeNombre}
                      textError={[
                        "Debe escribir un nombre",
                        "Solo se permiten letras (max. 20 caracteres)",
                      ]}
                      type="text"
                      valueError={MensajeNombre}
                      vacio={false}
                      expresionRegular={Expresiones.Letras}
                      maxLength={40}
                    />

                    <InputAntd
                      label="Apellido paterno:"
                      requiredICon={true}
                      className="inputblue"
                      value={ApellidoP}
                      setStateValue={setApellidoP}
                      style={{ margin: "1% 0" }}
                      placeholder="Escribe tu primer apellido"
                      setStateError={setMensajeApellidoP}
                      textError={[
                        "Debe escribir un apellido",
                        "Solo se permiten letras (max. 20 caracteres)",
                      ]}
                      type="text"
                      valueError={MensajeApellidoP}
                      vacio={false}
                      expresionRegular={Expresiones.Letras}
                      maxLength={20}
                    />

                    <InputAntd
                      label="Apellido materno:"
                      className="inputblue"
                      value={ApellidoM}
                      setStateValue={setApellidoM}
                      style={{ margin: "1% 0" }}
                      placeholder="Escribe tu segundo apellido"
                      setStateError={setMensajeApellidoM}
                      textError={[
                        "",
                        "Solo se permiten letras (max. 20 caracteres)",
                      ]}
                      type="text"
                      valueError={MensajeApellidoM}
                      vacio={true}
                      expresionRegular={Expresiones.Letras}
                      maxLength={20}
                    />

                    <InputAntd
                      label="Celular:"
                      className="inputblue"
                      value={Celular}
                      setStateValue={setCelular}
                      id="celular"
                      style={{ margin: "1% 0" }}
                      placeholder="Escribe tu celular"
                      setStateError={setMensajeCelular}
                      textError={[
                        "",
                        "Celular solo puede contener numeros",
                        "Celular debe contener 10 dígitos",
                      ]}
                      type="text"
                      valueError={MensajeCelular}
                      vacio={true}
                      expresionRegular={Expresiones.Numeros}
                      maxLength={10}
                    />

                    <InputAntd
                      label="Correo:"
                      className="inputblue"
                      value={Correo}
                      setStateValue={setCorreo}
                      style={{ margin: "1% 0" }}
                      placeholder="Escribe tu correo eléctronico"
                      setStateError={setMensajeCorreo}
                      textError={["", "Formato incorrecto"]}
                      type="text"
                      valueError={MensajeCorreo}
                      vacio={true}
                      expresionRegular={Expresiones.Correo}
                    />

                    <TextAreaAntd
                      label="Comentarios o notas:"
                      value={NotaContacto}
                      placeholder="Escriba un comentario o nota (opcional)"
                      style={{
                        marginBottom: ".5%",
                        fontFamily: "sans-serif",
                      }}
                      columns="30"
                      autoSize={true}
                      setStateValue={setNotaContacto}
                    />
                  </div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      style={{
                        width: "100%",
                        background: "#148f9f",
                        color: "white",
                      }}
                      onClick={() => {
                        editContact();
                      }}
                    >
                      Guardar
                    </Button>
                  </div>
                </form>
              </div>)}
          </div>

        </>
    )
}

export default DetallesContactoContent;