import React from 'react';
import { useDispatch } from 'react-redux';

import { SelectContact } from '../../redux/contactos';
import { ModalScreen, HideModal } from '../../redux/appSlice';

import "../comp-styles/ModalCitasAdd.scss"
import Iconos from "../IconosDeSitio"

function ContactList({ contactList, modalId }) {
  const dispatch = useDispatch();

  const selectContact = (contacto) => {
    let contact = { 
      id: contacto.IdContacto,
      nombre: `${contacto.Nombre} ${contacto.ApellidoPaterno} ${contacto.ApellidoMaterno}`,
      imagen: contacto.Imagen_url
    };
    
    dispatch(SelectContact(contact));
    
    dispatch(HideModal(modalId));
  }

  return (
    <>
      <div className="GeneralcontainCitasdd" >
        {contactList.map((contacto) => (
          <div className="PrinCardCitasdd" key={contacto.IdContacto} onClick={() => selectContact(contacto)}>
            <div className="ContainerCitasdd">
              <div className="CardCitasdd">
                <p className="BgrImgCitasdd">
                  <img
                    id={`img_add_contacto_cita_${contacto.IdContacto}`}
                    src={!!contacto.Imagen_url ? "https://" + contacto.Imagen_url : Iconos[0]}
                    onError={() => {
                      let img = document.getElementById(`img_add_contacto_cita_${contacto.IdContacto}`);
                      img.setAttribute("src", Iconos[0]);
                    }}
                  />
                </p>
                <div style={{ width: "95%" }}>
                  <b>{`${contacto.Nombre} ${contacto.ApellidoPaterno} ${contacto.ApellidoMaterno}`}</b>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

export default ContactList;