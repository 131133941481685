import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";

import { getConfigCitas_request } from "../../services/citas_services";

const QUERY_KEY = ['ConfigCitas'];
const fetchInterval = 5; // Minutos

const getHorariosDay = (start, end, intervalMinutes) => {
    let schedule = [];

    const startdate = dayjs(start, "hh:mm A");
    const enddate = dayjs(end, 'hh:mm A');
    
    let newdate = startdate;
    while(newdate<enddate){
        schedule.push(newdate.format('h:mm A').replace('AM', 'a.m').replace('PM', 'p.m'));
        newdate = newdate.add(intervalMinutes, 'minute');
    }

    return schedule;
}

const processHorarios = (horarios) => {
    let HorariosTrabajo = [[],[],[],[],[],[],[]];

    if(horarios.SU){
        HorariosTrabajo[0] = getHorariosDay(horarios.HoraInicioDomingo, horarios.HoraFinDomingo, horarios.TiempoCita);
    }
    if(horarios.MO){
        HorariosTrabajo[1] = getHorariosDay(horarios.HoraInicioLunes, horarios.HoraFinLunes, horarios.TiempoCita);
    }
    if(horarios.TU){
        HorariosTrabajo[2] = getHorariosDay(horarios.HoraInicioMartes, horarios.HoraFinMartes, horarios.TiempoCita);
    }
    if(horarios.WE){
        HorariosTrabajo[3] = getHorariosDay(horarios.HoraInicioMiercoles, horarios.HoraFinMiercoles, horarios.TiempoCita);
    }
    if(horarios.TH){
        HorariosTrabajo[4] = getHorariosDay(horarios.HoraInicioJueves, horarios.HoraFinJueves, horarios.TiempoCita);
    }
    if(horarios.FR){
        HorariosTrabajo[5] = getHorariosDay(horarios.HoraInicioViernes, horarios.HoraFinViernes, horarios.TiempoCita);
    }
    if(horarios.SA){
        HorariosTrabajo[6] = getHorariosDay(horarios.HoraInicioSabado, horarios.HoraFinSabado, horarios.TiempoCita);
    }

    return HorariosTrabajo;
}

const fetchData = async() => {
    let response = await getConfigCitas_request();

    let horariosData = {};
    if(response.ok){
        localStorage.setItem("duracionCita", response.data.TiempoCita ? response.data.TiempoCita.toString() : "60");
        const horarios = processHorarios(response.data);
        horariosData = {
            ...response.data,
            horariosConsulta: horarios
        }
    }
    
    return horariosData;
}

// TODO usar en la pantalla de configuracion de horarios
const useConfigCitasData = (token) => {
    const queryEnabled = token? true:false;
    return useQuery({
        queryFn: fetchData, 
        queryKey: QUERY_KEY,
        refetchInterval: (fetchInterval * 60000),
    })
};

export default useConfigCitasData;