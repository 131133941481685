import React, { useEffect, useState } from "react";
import { Button, DatePicker, Input, Row, Space, TimePicker } from "antd";
import "../comp-styles/ConfiguracionCitas.scss";
import {
  ContenedorBotonCentrado,
  Boton,
} from "../GeneralComponents/Formularios2Grids";
import { useNavigate, Link, useLocation } from "react-router-dom";
import Confcitas from "../GeneralComponents/General-Json";
import Eliminar from "../../Img/Iconos/eliminar.svg";
import {
  getConfigCitas_request,
  getBlockDates_request,
  saveConfigCita_request,
  saveBlockDates_request,
  deleteBlockDates_request,
} from "../../services/citas_services";
// import * as moment from 'moment';
import dayjs from "dayjs";

// iconos 
import Iconos from "../IconosDeSitio";
import LockClockIcon from '@mui/icons-material/LockClock';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { Label } from "../GeneralComponents/Formularios";
import { Checkbox } from "antd";
import { Loader } from "../GeneralComponents/Loader";
import SelectAntd from "../GeneralComponents/SelectAntd";
import { Content } from "antd/es/layout/layout";
import NavbarNuevo from "../GeneralComponents/Navbar_Nuevo";
import { ArrowBackIos } from "@mui/icons-material";
import ButtonAntd from "../GeneralComponents/ButtonAntd";

// const onChange = (e) => {
// console.log(`checked = ${e.target.checked}`);
// };

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function ConfiguracionCitas() {
  const navigate = useNavigate();

  // Declaracion de select para <p></p>rio
  const [TipoDeHorario, setTipoDeHorario] = useState("0");

  //Declaracion los dias de la semana
  const [DuracionCita, setDuracionCita] = useState("");

  const [HoraCita, setHoraCita] = useState("");
  const [MinutosCita, setMinutosCita] = useState("");

  const [Lunes, setLunes] = useState(false);
  const [HorarioInicioLunes, setHorarioInicioLunes] = useState("");
  const [HorarioFinLunes, setHorarioFinLunes] = useState("");
  const [Martes, setMartes] = useState(false);
  const [HorarioInicioMartes, setHorarioInicioMartes] = useState("");
  const [HorarioFinMartes, setHorarioFinMartes] = useState("");
  const [Miercoles, setMiercoles] = useState(false);
  const [HorarioInicioMiercoles, setHorarioInicioMiercoles] = useState("");
  const [HorarioFinMiercoles, setHorarioFinMiercoles] = useState("");
  const [Jueves, setJueves] = useState(false);
  const [HorarioInicioJueves, setHorarioInicioJueves] = useState("");
  const [HorarioFinJueves, setHorarioFinJueves] = useState("");
  const [Viernes, setViernes] = useState(false);
  const [HorarioInicioViernes, setHorarioInicioViernes] = useState("");
  const [HorarioFinViernes, setHorarioFinViernes] = useState("");
  const [Sabado, setSabado] = useState(false);
  const [HorarioInicioSabado, setHorarioInicioSabado] = useState("");
  const [HorarioFinSabado, setHorarioFinSabado] = useState("");
  const [Domingo, setDomingo] = useState(false);
  const [HorarioInicioDomingo, setHorarioInicioDomingo] = useState("");
  const [HorarioFinDomingo, setHorarioFinDomingo] = useState("");

  const [Semana, setSemana] = useState(false);
  const [HorarioInicioSemana, setHorarioInicioSemana] = useState("");
  const [HorarioFinSemana, setHorarioFinSemana] = useState("");

  const [ListBlockDates, setListBlockDates] = useState([]);

  const [FechaInicio, setFechaInicio] = useState("");
  const [FechaFin, setFechaFin] = useState("");

  // MANEJO DE MENSAJES
  const [open, setOpen] = useState(false);
  const [typeMessage, setTypeMessage] = useState("");
  const [message, setMessage] = useState("");

  const [loader, setLoader] = useState(false);

  const [MensajeDuracionCita, setMensajeDuracionCita] = useState("true");

  async function getConfigCitas() {
    setLoader(true);
    let response = await getConfigCitas_request();
    if (response.ok) {
      console.log(response.data);
      if (response.data.TipoHorario !== null) {
        setTipoDeHorario(response.data.TipoHorario.toString());
      setSemana(response.data.TipoHorario === 1 ? true : false);
     
    }
      // console.log(response.data.TipoHorario); 
      setDuracionCita(response.data.TiempoCita.toString());

      setHorarioInicioSemana(response.data.HoraInicioLunes);
      setHorarioFinSemana(response.data.HoraFinLunes);

      setLunes(response.data.MO === 1 ? true : false);
      setHorarioInicioLunes(response.data.HoraInicioLunes);
      setHorarioFinLunes(response.data.HoraFinLunes);

      setMartes(response.data.TU === 1 ? true : false);
      setHorarioInicioMartes(response.data.HoraInicioMartes);
      setHorarioFinMartes(response.data.HoraFinMartes);

      setMiercoles(response.data.WE === 1 ? true : false);
      setHorarioInicioMiercoles(response.data.HoraInicioMiercoles);
      setHorarioFinMiercoles(response.data.HoraFinMiercoles);

      setJueves(response.data.TH === 1 ? true : false);
      setHorarioInicioJueves(response.data.HoraInicioJueves);
      setHorarioFinJueves(response.data.HoraFinJueves);

      setViernes(response.data.FR === 1 ? true : false);
      setHorarioInicioViernes(response.data.HoraInicioViernes);
      setHorarioFinViernes(response.data.HoraFinViernes);

      setSabado(response.data.SA === 1 ? true : false);
      setHorarioInicioSabado(response.data.HoraInicioSabado);
      setHorarioFinSabado(response.data.HoraFinSabado);

      setDomingo(response.data.SU === 1 ? true : false);
      setHorarioInicioDomingo(response.data.HoraInicioDomingo);
      setHorarioFinDomingo(response.data.HoraFinDomingo);
    } else {
      setOpen(true);
      setTypeMessage("error");
      setMessage(response.mensaje);
    }
    setLoader(false);
    return;
  }

  async function getBlockDates() {
    let response = await getBlockDates_request();
    if (response.ok) {
      // console.log(response.data);
      setListBlockDates(response.data);
    } else {
      setListBlockDates([]);
    }
  }

  const guardarConfigCita = async (e) => {
    setLoader(true);
    if (
      Lunes ||
      Martes ||
      Miercoles ||
      Jueves ||
      Viernes ||
      Sabado ||
      Domingo
    ) {
      // console.log(DuracionCita);
      if (DuracionCita !== "00" && DuracionCita !== "") {
        let data = new FormData();

        data.append("TiempoCita", DuracionCita);
        data.append("tipoHorario", !!TipoDeHorario ? TipoDeHorario : 0);
        // console.log(Semana);
        if (Semana) {
          if (Lunes) {
            data.append("lunes", Lunes);
            data.append("HoraInicioLunes", HorarioInicioSemana);
            data.append("HoraFinLunes", HorarioFinSemana);
          }
          if (Martes) {
            data.append("martes", Martes);
            data.append("HoraInicioMartes", HorarioInicioSemana);
            data.append("HoraFinMartes", HorarioFinSemana);
          }
          if (Miercoles) {
            data.append("miercoles", Miercoles);
            data.append("HoraInicioMiercoles", HorarioInicioSemana);
            data.append("HoraFinMiercoles", HorarioFinSemana);
          }
          if (Jueves) {
            data.append("jueves", Jueves);
            data.append("HoraInicioJueves", HorarioInicioSemana);
            data.append("HoraFinJueves", HorarioFinSemana);
          }
          if (Viernes) {
            data.append("viernes", Viernes);
            data.append("HoraInicioViernes", HorarioInicioSemana);
            data.append("HoraFinViernes", HorarioFinSemana);
          }
          if (Sabado) {
            // console.log(HorarioInicioSemana);
            // console.log(HorarioFinSemana);
            data.append("sabado", Sabado);
            data.append("HoraInicioSabado", HorarioInicioSemana);
            data.append("HoraFinSabado", HorarioFinSemana);
          }
          if (Domingo) {
            data.append("domingo", Domingo);
            data.append("HoraInicioDomingo", HorarioInicioSemana);
            data.append("HoraFinDomingo", HorarioFinSemana);
          }
        } else {
          if (Lunes) {
            data.append("lunes", Lunes);
            data.append("HoraInicioLunes", HorarioInicioLunes);
            data.append("HoraFinLunes", HorarioFinLunes);
          }
          if (Martes) {
            data.append("martes", Martes);
            data.append("HoraInicioMartes", HorarioInicioMartes);
            data.append("HoraFinMartes", HorarioFinMartes);
          }
          if (Miercoles) {
            data.append("miercoles", Miercoles);
            data.append("HoraInicioMiercoles", HorarioInicioMiercoles);
            data.append("HoraFinMiercoles", HorarioFinMiercoles);
          }
          if (Jueves) {
            data.append("jueves", Jueves);
            data.append("HoraInicioJueves", HorarioInicioJueves);
            data.append("HoraFinJueves", HorarioFinJueves);
          }
          if (Viernes) {
            data.append("viernes", Viernes);
            data.append("HoraInicioViernes", HorarioInicioViernes);
            data.append("HoraFinViernes", HorarioFinViernes);
          }
          if (Sabado) {
            data.append("sabado", Sabado);
            data.append("HoraInicioSabado", HorarioInicioSabado);
            data.append("HoraFinSabado", HorarioFinSabado);
          }
          if (Domingo) {
            data.append("domingo", Domingo);
            data.append("HoraInicioDomingo", HorarioInicioDomingo);
            data.append("HoraFinDomingo", HorarioFinDomingo);
          }
        }
        // console.log("Lunes");
        // console.log(Lunes);
        // console.log(HorarioInicioLunes);
        // console.log(HorarioFinLunes);
        // console.log("Martes");
        // console.log(Martes);
        // console.log(HorarioInicioMartes);
        // console.log(HorarioFinMartes);
        // console.log("Miercoles");
        // console.log(Miercoles);
        // console.log(HorarioInicioMiercoles);
        // console.log(HorarioFinMiercoles);
        // console.log("Jueves");
        // console.log(Jueves);
        // console.log(HorarioInicioJueves);
        // console.log(HorarioFinJueves);
        // console.log("Viernes");
        // console.log(Viernes);
        // console.log(HorarioInicioViernes);
        // console.log(HorarioFinViernes);
        // console.log("Sabado");
        // console.log(Sabado);
        // console.log(HorarioInicioSabado);
        // console.log(HorarioFinSabado);
        // console.log("Domingo");
        // console.log(Domingo);
        // console.log(HorarioInicioDomingo);
        // console.log(HorarioFinDomingo);
        // return;
        let response = await saveConfigCita_request(data);
        if (response.ok) {
          setOpen(true);
          setTypeMessage("success");
          setMessage("Configuracion guardada");
          getConfigCitas();
        } else {
          setOpen(true);
          setTypeMessage("error");
          setMessage(response.mensaje);
        }
      } else {
        setOpen(true);
        setTypeMessage("warning");
        setMessage("Debe elegir un tiempo de cita");
      }
    } else {
      setOpen(true);
      setTypeMessage("warning");
      setMessage("Debe poner al menos un horario");
    }
    setLoader(false);
    return;
  };

  const guardarPeriodoBloqueado = async (e) => {
    e.preventDefault();
    setLoader(true);
    let data = new FormData();
    data.append("FechaInicio", FechaInicio);
    data.append("FechaFin", FechaFin);
    if (FechaInicio !== "" && FechaFin !== "") {
      let response = await saveBlockDates_request(data);
      if (response.ok) {
        setOpen(true);
        setTypeMessage("success");
        setMessage("Periodo bloqueada");
        setFechaInicio("");
        setFechaFin("");
        getBlockDates();
      } else {
        setOpen(true);
        setTypeMessage("error");
        setMessage(response.mensaje);
      }
    } else {
      setOpen(true);
      setTypeMessage("warning");
      setMessage("Debes llenar ambas fechas");
    }
    setLoader(false);
    return;
  };

  async function deleteBlockDates(idBloqueo) {
    setLoader(true);
    let response = await deleteBlockDates_request(idBloqueo);
    if (response.ok) {
      setOpen(true);
      setTypeMessage("success");
      setMessage("Periodo eliminado");
      getBlockDates();
    } else {
      setOpen(true);
      setTypeMessage("error");
      setMessage(response.mensaje);
    }
    setLoader(false);
    return;
  }

  useEffect(() => {
    getConfigCitas();
    getBlockDates();
    // return;
  }, []);

  return (
    <Stack>
      <NavbarNuevo
        mensajesError={{
          setOpen: setOpen,
          setTypeMessage: setTypeMessage,
          setMessage: setMessage,
        }}
        searchBar={
          <div className="BackConfigStyle">
            <Link to="/app/Citas">
              <img className="iconBack" src={Iconos[23]} alt="" />
            </Link>
            <b style={{ fontSize: "150%" }}>Horario de citas</b>
          </div>
        }
      />
      <Content
        className="site-layout"
        style={{
          padding: "0 3%",
          width: "100%",
          overflow: "initial",
        }}
      >
        {loader ? (
          <Loader />
        ) : (
          <>
            <div>
              <div style={{ padding: "1%" }}>
                <Row>
                  <Row>
                    <label htmlFor="">Selecciona tu tipo de horario:</label>
                    <select
                      onChange={(e) => {
                        setTipoDeHorario(e.target.value);
                        if (e.target.value === "1") {
                          setSemana(true);
                          setTipoDeHorario("1");
                        } else {
                          setSemana(false);
                          setHorarioInicioSemana("");
                          setHorarioFinSemana("");
                          setTipoDeHorario("0");
                        }
                        // console.log(e.target.value);
                      }}
                      // defaultValue={TipoDeHorario}
                      value={TipoDeHorario}
                      name=""
                      id="HorarioTipo"
                      className="inputblue"
                      style={{
                        borderRadius: ".4rem",
                        width: "75%",
                        height: "35px",
                        padding: "0%",
                      }}
                    >
                      <option
                        style={{ color: "#148f9f" }}
                        value=""
                        defaultChecked
                        disabled
                      >
                        Seleciona una opcion
                      </option>
                      <option value="0">Config.Horario por dia</option>
                      <option value="1">Config.Horario por semana</option>
                    </select>
                  </Row>
                </Row>
              </div>
            </div>
            {TipoDeHorario === "0" ? (
              <div className="confcitas">
                {/* { SelecionadoTipo === 0 ? <div>POR DIA</div> : <div>pOR SEMANA</div>} */}
                <form>
                  <Row style={{ justifyContent: "space-between" }}>
                    <div>
                      <div>
                        <Row>
                          <Checkbox
                            checked={Lunes}
                            onChange={(e) => {
                              if (!Lunes) {
                                setLunes(true);
                                setHorarioInicioLunes("9:00 AM");
                                setHorarioFinLunes("4:00 PM");
                              } else {
                                setLunes(false);
                                setHorarioInicioLunes("");
                                setHorarioFinLunes("");
                              }
                            }}
                          >
                            Lunes
                          </Checkbox>
                        </Row>
                      </div>
                      <div>
                        <TimePicker.RangePicker
                          value={[
                            !!HorarioInicioLunes &&
                            HorarioInicioLunes !== "00:00:00" &&
                            HorarioInicioLunes.substring(0, 3) !== "00:00"
                              ? dayjs(HorarioInicioLunes, "h:mm A")
                              : "",
                            !!HorarioFinLunes &&
                            HorarioFinLunes !== "00:00:00" &&
                            HorarioFinLunes.substring(0, 3) !== "00:00"
                              ? dayjs(HorarioFinLunes, "h:mm A")
                              : "",
                          ]}
                          use12Hours={true}
                          format="h:mm A"
                          onChange={(e) => {
                            // console.log(e[0].format("h:mm A"));
                            setHorarioInicioLunes(e[0].format("h:mm A"));
                            setHorarioFinLunes(e[1].format("h:mm A"));
                          }}
                          disabled={!Lunes}
                        />
                        <br />
                        <br />
                      </div>
                    </div>
                    <div>
                      <div>
                        <Row>
                          <Checkbox
                            checked={Martes}
                            onChange={(e) => {
                              if (!Martes) {
                                setMartes(true);
                                setHorarioInicioMartes("9:00 AM");
                                setHorarioFinMartes("4:00 PM");
                              } else {
                                setMartes(false);
                                setHorarioInicioMartes("");
                                setHorarioFinMartes("");
                              }
                            }}
                          >
                            Martes
                          </Checkbox>
                        </Row>
                      </div>
                      <div>
                        <TimePicker.RangePicker
                          value={[
                            !!HorarioInicioMartes &&
                            HorarioInicioMartes !== "00:00:00" &&
                            HorarioInicioMartes.substring(0, 3) !== "00:00"
                              ? dayjs(HorarioInicioMartes, "h:mm A")
                              : "",
                            !!HorarioFinMartes &&
                            HorarioFinMartes !== "00:00:00" &&
                            HorarioInicioMartes.substring(0, 3) !== "00:00"
                              ? dayjs(HorarioFinMartes, "h:mm A")
                              : "",
                          ]}
                          use12Hours={true}
                          format="h:mm A"
                          onChange={(e) => {
                            setHorarioInicioMartes(e[0].format("h:mm A"));
                            setHorarioFinMartes(e[1].format("h:mm A"));
                          }}
                          disabled={!Martes}
                        />
                        <br />
                        <br />
                      </div>
                    </div>
                    <div>
                      <div>
                        <Row>
                          <Checkbox
                            checked={Miercoles}
                            onChange={(e) => {
                              if (!Miercoles) {
                                setMiercoles(true);
                                setHorarioInicioMiercoles("9:00 AM");
                                setHorarioFinMiercoles("4:00 PM");
                              } else {
                                setMiercoles(false);
                                setHorarioInicioMiercoles("");
                                setHorarioFinMiercoles("");
                              }
                            }}
                          >
                            Miércoles
                          </Checkbox>
                        </Row>
                      </div>
                      <div>
                        <TimePicker.RangePicker
                          value={[
                            !!HorarioInicioMiercoles &&
                            HorarioInicioMiercoles !== "00:00:00" &&
                            HorarioInicioMiercoles.substring(0, 3) !== "00:00"
                              ? dayjs(HorarioInicioMiercoles, "h:mm A")
                              : "",
                            !!HorarioFinMiercoles &&
                            HorarioFinMiercoles !== "00:00:00" &&
                            HorarioFinMiercoles.substring(0, 3) !== "00:00"
                              ? dayjs(HorarioFinMiercoles, "h:mm A")
                              : "",
                          ]}
                          use12Hours={true}
                          format="h:mm A"
                          onChange={(e) => {
                            setHorarioInicioMiercoles(e[0].format("h:mm A"));
                            setHorarioFinMiercoles(e[1].format("h:mm A"));
                          }}
                          disabled={!Miercoles}
                        />
                        <br />
                        <br />
                      </div>
                    </div>
                  </Row>
                  <hr />
                  <br />
                  <Row style={{ justifyContent: "space-between" }}>
                    <div>
                      <div>
                        <Row>
                          <Checkbox
                            checked={Jueves}
                            onChange={(e) => {
                              if (!Jueves) {
                                setJueves(true);
                                setHorarioInicioJueves("9:00 AM");
                                setHorarioFinJueves("4:00 PM");
                              } else {
                                setJueves(false);
                                setHorarioInicioJueves("");
                                setHorarioFinJueves("");
                              }
                            }}
                          >
                            Jueves
                          </Checkbox>
                        </Row>
                      </div>
                      <div>
                        <TimePicker.RangePicker
                          value={[
                            !!HorarioInicioJueves &&
                            HorarioInicioJueves !== "00:00:00" &&
                            HorarioInicioJueves.substring(0, 3) !== "00:00"
                              ? dayjs(HorarioInicioJueves, "h:mm A")
                              : "",
                            !!HorarioFinJueves &&
                            HorarioFinJueves !== "00:00:00" &&
                            HorarioFinJueves.substring(0, 3) !== "00:00"
                              ? dayjs(HorarioFinJueves, "h:mm A")
                              : "",
                          ]}
                          use12Hours={true}
                          format="h:mm A"
                          onChange={(e) => {
                            setHorarioInicioJueves(e[0].format("h:mm A"));
                            setHorarioFinJueves(e[1].format("h:mm A"));
                          }}
                          disabled={!Jueves}
                        />
                        <br />
                        <br />
                      </div>
                    </div>
                    <div>
                      <div>
                        <Row>
                          <Checkbox
                            checked={Viernes}
                            onChange={(e) => {
                              if (!Viernes) {
                                setViernes(true);
                                setHorarioInicioViernes("9:00 AM");
                                setHorarioFinViernes("4:00 PM");
                              } else {
                                setViernes(false);
                                setHorarioInicioViernes("");
                                setHorarioFinViernes("");
                              }
                            }}
                          >
                            Viernes
                          </Checkbox>
                        </Row>
                      </div>
                      <div>
                        <TimePicker.RangePicker
                          value={[
                            !!HorarioInicioViernes &&
                            HorarioInicioViernes !== "00:00:00" &&
                            HorarioInicioViernes.substring(0, 3) !== "00:00"
                              ? dayjs(HorarioInicioViernes, "h:mm A")
                              : "",
                            !!HorarioFinViernes &&
                            HorarioFinViernes !== "00:00:00" &&
                            HorarioFinViernes.substring(0, 3) !== "00:00"
                              ? dayjs(HorarioFinViernes, "h:mm A")
                              : "",
                          ]}
                          use12Hours={true}
                          format="h:mm A"
                          onChange={(e) => {
                            setHorarioInicioViernes(e[0].format("h:mm A"));
                            setHorarioFinViernes(e[1].format("h:mm A"));
                          }}
                          disabled={!Viernes}
                        />
                        <br />
                        <br />
                      </div>
                    </div>
                    <div>
                      <div>
                        <Row>
                          <Checkbox
                            checked={Sabado}
                            onChange={(e) => {
                              if (!Sabado) {
                                setSabado(true);
                                setHorarioInicioSabado("9:00 AM");
                                setHorarioFinSabado("4:00 PM");
                              } else {
                                setSabado(false);
                                setHorarioInicioSabado("");
                                setHorarioFinSabado("");
                              }
                            }}
                          >
                            Sábado
                          </Checkbox>
                        </Row>
                      </div>
                      <div>
                        <TimePicker.RangePicker
                          value={[
                            !!HorarioInicioSabado &&
                            HorarioInicioSabado !== "00:00:00" &&
                            HorarioInicioSabado.substring(0, 3) !== "00:00"
                              ? dayjs(HorarioInicioSabado, "h:mm A")
                              : "",
                            !!HorarioFinSabado &&
                            HorarioFinSabado !== "00:00:00" &&
                            HorarioFinSabado.substring(0, 3) !== "00:00"
                              ? dayjs(HorarioFinSabado, "h:mm A")
                              : "",
                          ]}
                          use12Hours={true}
                          format="h:mm A"
                          onChange={(e) => {
                            setHorarioInicioSabado(e[0].format("h:mm A"));
                            setHorarioFinSabado(e[1].format("h:mm A"));
                          }}
                          disabled={!Sabado}
                        />
                        <br />
                        <br />
                      </div>
                    </div>
                  </Row>
                  <hr />
                  <br />
                  <Row style={{ justifyContent: "space-between" }}>
                    <div>
                      <div>
                        <Row>
                          <Checkbox
                            checked={Domingo}
                            onChange={(e) => {
                              if (!Domingo) {
                                setDomingo(true);
                                setHorarioInicioDomingo("9:00 AM");
                                setHorarioFinDomingo("4:00 PM");
                              } else {
                                setDomingo(false);
                                setHorarioInicioDomingo("");
                                setHorarioFinDomingo("");
                              }
                            }}
                          >
                            Domingo
                          </Checkbox>
                        </Row>
                      </div>
                      <div>
                        <TimePicker.RangePicker
                          value={[
                            !!HorarioInicioDomingo &&
                            HorarioInicioDomingo !== "00:00:00" &&
                            HorarioInicioDomingo.substring(0, 3) !== "00:00"
                              ? dayjs(HorarioInicioDomingo, "h:mm A")
                              : "",
                            !!HorarioFinDomingo &&
                            HorarioFinDomingo !== "00:00:00" &&
                            HorarioFinDomingo.substring(0, 3) !== "00:00"
                              ? dayjs(HorarioFinDomingo, "h:mm A")
                              : "",
                          ]}
                          use12Hours={true}
                          format="h:mm A"
                          onChange={(e) => {
                            setHorarioInicioDomingo(e[0].format("h:mm A"));
                            setHorarioFinDomingo(e[1].format("h:mm A"));
                          }}
                          disabled={!Domingo}
                        />
                      </div>
                    </div>

                    <div style={{ width: "37%" }}>
                      <div>
                        <SelectAntd
                          label="Tiempo de cita:"
                          placeholder="Elige un tiempo de cita"
                          options={[
                            { value: "10", label: "10 Min." },
                            { value: "20", label: "20 Min." },
                            { value: "30", label: "30 Min." },
                            { value: "40", label: "40 Min." },
                            { value: "50", label: "50 Min." },
                            { value: "60", label: "1 Hr." },
                            // { value: "70", label: "1 Hr. 10 Min." },
                            // { value: "80", label: "1 Hr. 20 Min." },
                            // { value: "90", label: "1 Hr. 30 Min." },
                            // { value: "100", label: "1 Hr. 40 Min." },
                            // { value: "110", label: "1 Hr. 50 Min." },
                            // { value: "120", label: "2 Hrs." },
                          ]}
                          style={{ width: "100%", fontWeight: "normal" }}
                          setStateValue={setDuracionCita}
                          value={DuracionCita}
                          setStateError={setMensajeDuracionCita}
                          valueError={MensajeDuracionCita}
                          vacio={false}
                        />
                        {/* <DatePicker
                          picker="time"
                          showNow={false}
                          showSecond={false}
                          showMinute={false}
                          onSelect={(e) => {
                            // console.log(e);
                            // console.log(e.format("hh a"));
                            setHoraCita(e.format("hh"));
                          }}
                          placeholder="Horas"
                          format="hh"
                        value={
                          HoraCita !== "" ? dayjs(HoraCita, "hh") : ""
                        }
                        /> */}
                        {/* <DatePicker
                          picker="time"
                          showNow={false}
                          showSecond={false}
                          showHour={false}

                          onSelect={(e) => {
                            // console.log(e);
                            // console.log(e.format("hh:mm a"));
                            setMinutosCita(e.format("mm"));
                          }}
                          placeholder="Minutos"
                          format="mm"
                        // value={
                        //   DuracionCita !== "" ? dayjs(DuracionCita, "hh:mm") : ""
                        // }
                        /> */}
                        {/* <TimePicker
                          style={{ width: "100%" }}
                          value={
                            DuracionCita !== "" ? dayjs(DuracionCita, "hh:mm") : ""
                            // DuracionCita !== "" ? DuracionCita : ""
                          }
                          format="hh:mm"
                          minuteStep={10}
                          showMinute={true}
                          showHour={true}
                          showSecond={false}
                          onOk={(e) => {
                            // console.log(e.format("hh:mm"));
                            setTiempoCita(e.format("hh:mm"));
                          }}
                          // onSelect={(e) => {
                          // console.log(e);
                          //   setTiempoCita(e.format("hh:mm"));
                          // }}
                          showNow={false}
                        /> */}
                        {/* <TimePicker style={{ width: "100%" }} format="mm" minuteStep={10} onOk={(e) => {setTiempoCita(e.format("mm")); }} /> */}
                      </div>
                    </div>
                  </Row>
                  <br />
                  <br />
                  {/* <hr /> */}
                  <br />
                  <ContenedorBotonCentrado>
                    {/* <button type="submit" className="GeneralBoton">
                      Guardar
                    </button> */}
                    <ButtonAntd 
                   
                      onClick={() => {
                        guardarConfigCita();
                      }}
                    >
                      
                      <Row style={{display:"flex", justifyContent:"space-between", alignItems:"center", width:"80px" }}>
                        Guardar
                        <CheckCircleIcon style={{fontSize:"15px"}}/>
                      </Row>
                    </ButtonAntd>
                  </ContenedorBotonCentrado>
                </form>
              </div>
            ) : (
              <div style={{ padding: "1% 2%", width: "100%", background:"#fff", borderRadius:".5rem", marginBottom:"10px" }}>
                <form>
                  <div>
                    <div style={{ textAlign: "center", margin: "1%" }}>
                      <h2>Selecciona los días hábiles</h2>
                    </div>
                    <Row style={{ gap: "2%", justifyContent: "center" }}>
                      <Row>
                        <Checkbox
                          checked={Lunes}
                          onChange={(e) => {
                            setLunes(e.target.checked);
                            if (
                              !e.target.checked &&
                              !Martes &&
                              !Miercoles &&
                              !Jueves &&
                              !Viernes &&
                              !Sabado &&
                              !Domingo
                            ) {
                              setHorarioInicioSemana("");
                              setHorarioFinSemana("");
                            }
                          }}
                        >
                          Lunes
                        </Checkbox>
                      </Row>
                      <Row>
                        <Checkbox
                          checked={Martes}
                          onChange={(e) => {
                            setMartes(e.target.checked);
                            if (
                              !Lunes &&
                              !e.target.checked &&
                              !Miercoles &&
                              !Jueves &&
                              !Viernes &&
                              !Sabado &&
                              !Domingo
                            ) {
                              setHorarioInicioSemana("");
                              setHorarioFinSemana("");
                            }
                          }}
                        >
                          Martes
                        </Checkbox>
                      </Row>
                      <Row>
                        <Checkbox
                          checked={Miercoles}
                          onChange={(e) => {
                            setMiercoles(e.target.checked);
                            if (
                              !Lunes &&
                              !Martes &&
                              !e.target.checked &&
                              !Jueves &&
                              !Viernes &&
                              !Sabado &&
                              !Domingo
                            ) {
                              setHorarioInicioSemana("");
                              setHorarioFinSemana("");
                            }
                          }}
                        >
                          Miércoles
                        </Checkbox>
                      </Row>
                      <Row>
                        <Checkbox
                          checked={Jueves}
                          onChange={(e) => {
                            setJueves(e.target.checked);
                            if (
                              !Lunes &&
                              !Martes &&
                              !Miercoles &&
                              !e.target.checked &&
                              !Viernes &&
                              !Sabado &&
                              !Domingo
                            ) {
                              setHorarioInicioSemana("");
                              setHorarioFinSemana("");
                            }
                          }}
                        >
                          Jueves
                        </Checkbox>
                      </Row>
                      <Row>
                        <Checkbox
                          checked={Viernes}
                          onChange={(e) => {
                            setViernes(e.target.checked);
                            if (
                              !Lunes &&
                              !Martes &&
                              !Miercoles &&
                              !Jueves &&
                              !e.target.checked &&
                              !Sabado &&
                              !Domingo
                            ) {
                              setHorarioInicioSemana("");
                              setHorarioFinSemana("");
                            }
                          }}
                        >
                          Viernes
                        </Checkbox>
                      </Row>
                      <Row>
                        <Checkbox
                          checked={Sabado}
                          onChange={(e) => {
                            setSabado(e.target.checked);
                            if (
                              !Lunes &&
                              !Martes &&
                              !Miercoles &&
                              !Jueves &&
                              !Viernes &&
                              !e.target.checked &&
                              !Domingo
                            ) {
                              setHorarioInicioSemana("");
                              setHorarioFinSemana("");
                            }
                          }}
                        >
                          Sábado
                        </Checkbox>
                      </Row>
                      <Row>
                        <Checkbox
                          checked={Domingo}
                          onChange={(e) => {
                            setDomingo(e.target.checked);
                            if (
                              !Lunes &&
                              !Martes &&
                              !Miercoles &&
                              !Jueves &&
                              !Viernes &&
                              !Sabado &&
                              !e.target.checked
                            ) {
                              setHorarioInicioSemana("");
                              setHorarioFinSemana("");
                            }
                          }}
                        >
                          Domingo
                        </Checkbox>
                      </Row>
                    </Row>
                    <Row
                      style={{
                        justifyContent: "center",
                        gap: "1%",
                        marginTop: "2%",
                        alignItems: "center",
                      }}
                    >
                      <div>
                        <Label>Seleciona tu Horario</Label>
                        <TimePicker.RangePicker
                          style={{ width: "100%" }}
                          value={[
                            !!HorarioInicioSemana &&
                            HorarioInicioSemana !== "00:00:00" &&
                            HorarioInicioSemana.substring(0, 3) !== "00:00"
                              ? dayjs(HorarioInicioSemana, "h:mm A")
                              : "",
                            !!HorarioFinSemana &&
                            HorarioFinSemana !== "00:00:00" &&
                            HorarioFinSemana.substring(0, 3) !== "00:00"
                              ? dayjs(HorarioFinSemana, "h:mm A")
                              : "",
                          ]}
                          use12Hours={true}
                          format="h:mm A"
                          onChange={(e) => {
                            setHorarioInicioSemana(e[0].format("h:mm A"));
                            setHorarioFinSemana(e[1].format("h:mm A"));
                          }}
                          disabled={
                            Lunes ||
                            Martes ||
                            Miercoles ||
                            Jueves ||
                            Viernes ||
                            Sabado ||
                            Domingo
                              ? false
                              : true
                          }
                        />
                      </div>

                      <SelectAntd
                        label="Tiempo de cita:"
                        placeholder="Elige un tiempo de cita"
                        options={[
                          { value: "10", label: "10 Min." },
                          { value: "20", label: "20 Min." },
                          { value: "30", label: "30 Min." },
                          { value: "40", label: "40 Min." },
                          { value: "50", label: "50 Min." },
                          { value: "60", label: "1 Hr." },
                          // { value: "70", label: "1 Hr. 10 Min." },
                          // { value: "80", label: "1 Hr. 20 Min." },
                          // { value: "90", label: "1 Hr. 30 Min." },
                          // { value: "100", label: "1 Hr. 40 Min." },
                          // { value: "110", label: "1 Hr. 50 Min." },
                          // { value: "120", label: "2 Hrs." },
                        ]}
                        style={{ width: "100%" }}
                        setStateValue={setDuracionCita}
                        value={DuracionCita}
                        setStateError={setMensajeDuracionCita}
                        valueError={MensajeDuracionCita}
                        vacio={false}
                      />
                    </Row>
                  </div>
                  <ContenedorBotonCentrado>
                    <ButtonAntd
                      onClick={() => {
                        guardarConfigCita();
                      }}
                    >
                       <Row style={{display:"flex", justifyContent:"space-between", alignItems:"center", width:"80px" }}>
                        Guardar
                        <CheckCircleIcon style={{fontSize:"15px"}}/>
                      </Row>
                    </ButtonAntd>
                  </ContenedorBotonCentrado>
                </form>
              </div>
            )}
           
            <form onSubmit={guardarPeriodoBloqueado}>
              <div style={{textAlign:"center", background:"#fff", padding:"3%", margin:"1% 0", borderRadius:".5rem"}}>
                <h2>
                  Bloqueo de fecha para citas
                </h2>
                <ContenedorBotonCentrado>
                  <p style={{ paddingBottom: "1.5%" }}>Periodo de fechas</p>
                  <Row
                    style={{
                      width:"100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems:"center",
                      gap:"1%"
                    }}
                  >
                    <Input
                      type="date"
                      style={{ width: "145px" }}
                      value={FechaInicio}
                      onChange={(e) => {
                        setFechaInicio(e.target.value);
                      }}
                    />
                    <p
                    >
                      A
                    </p>
                    <Input
                      type="date"
                      style={{ width: "145px" }}
                      value={FechaFin}
                      onChange={(e) => {
                        setFechaFin(e.target.value);
                      }}
                    />
                  </Row>
                  <Button htmlType="submit" className="GeneralBoton" style={{color:"white"}}>
                    <Row style={{width:"80px",gap:"1%", alignItems:"center", justifyContent:"space-between"}}>
                    Bloquear <LockClockIcon style={{fontSize:"15px"}}/>
                    </Row>
                  </Button>
                </ContenedorBotonCentrado>
              </div>
            </form>

            <Row style={{width:"100%", display:"flex", alignItems:"center", justifyContent:"center", gap:"1%"}}>
              {ListBlockDates.map((fechaBloqueada) => (
            
                  <div
                    style={{
                      display:"flex",
                      background: "white",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "1% 2%",
                      width: "300px",
                      marginBottom: "3px",
                    }}
                  >
                    <div>
                      <b>Periodo Bloqueado</b>
                      <p>
                        {fechaBloqueada.FechaInicio} - {fechaBloqueada.FechaFin}
                      </p>
                    </div>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <div>
                      <img
                        onClick={() => {
                          deleteBlockDates(fechaBloqueada.IdBloqueo);
                        }}
                        style={{ width: "18px", cursor: "pointer" }}
                        src={Eliminar}
                        alt=""
                      />
                    </div>
                  </div>
             
              ))}
            </Row>
          </>
        )}
      </Content>

      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
      >
        <Alert
          onClose={() => setOpen(false)}
          severity={typeMessage}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </Stack>
  );
}

export default ConfiguracionCitas;
