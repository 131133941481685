import { createSlice } from '@reduxjs/toolkit';
import { Views } from 'react-big-calendar';

const initialState = { 
    fechaInicio: '',
    fechaFinal: '',
    calendarDate:'',
    selectedDay:'',
    reloadCitas: true,
    selectedCitaId: '',
    calendarView: Views.MONTH
};

const setFechaInicial = (state, data) =>{ state.fechaInicio = data;}

const setFechaFinal = (state, data) => { state.fechaFinal = data; }

const setFechaCalendar = (state, data) => { state.calendarDate = data; }

const setSelectedDay = (state, data) => { state.selectedDay = data; }

const setReloadCitas = (state, data) => { state.reloadCitas = data; }

const setSelectedCitaId = (state, data) =>{ state.selectedCitaId = data; }

const setCalendarView = (state, data) => { state.calendarView = data;}

export const citasSlice = createSlice({
    name: 'citas',
    initialState,
    reducers: {
        SetFechaInicial: (state, action) => setFechaInicial(state, action.payload),
        SetFechaFinal: (state, action) => setFechaFinal(state, action.payload),
        SetCalendarDate: (state, action) => setFechaCalendar(state, action.payload),
        SelectCitaDate: (state, action) => setSelectedDay(state, action.payload),
        SetReloadCitas: (state, action) => setReloadCitas(state, action.payload),
        SetCitaId: (state, action) => setSelectedCitaId(state, action.payload),
        SetCalendarView: (state, action) => setCalendarView(state, action.payload)
    }
});

export const { SetFechaInicial, SetFechaFinal, SetCalendarDate, SelectCitaDate, SetReloadCitas, SetCitaId, SetCalendarView } = citasSlice.actions;

export default citasSlice.reducer;