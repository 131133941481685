import { configureStore } from '@reduxjs/toolkit';

import snackbarReducer from './snackbarSlice';
import userReducer from './userSlice';
import doctorsToAssistantSlice from './doctorsToAssistant';
import infoDoctorLog from './infoDoctorLog';
import badges from './badges';
import navBarProps from './navBarProps';
import notifications from './notifications';
import citas from './citas';
import contactos from './contactos';
import app from './appSlice';
import horariosAtencion from './horariosAtencion';

const store = configureStore(
  {
    reducer: {
      snackbar: snackbarReducer,
      userInfo: userReducer,
      doctorsLink: doctorsToAssistantSlice,
      infoDoctorLog: infoDoctorLog,
      badges: badges,
      navBarProps: navBarProps,
      notifications: notifications,
      appointments: citas,
      contacts: contactos,
      app: app,
      horarios: horariosAtencion
    }
  }
)

export default store;