import { createSlice } from '@reduxjs/toolkit';

export const ModalScreen = {
    ADD_CITA: 'addCita',
    SELECT_CONTACT: 'selectContact',
    ADD_CONTACT: 'addContact',
    DETALLE_CITA: 'detallesCita',
    FICHA_PACIENTE: 'fichaPaciente',
    DETALLE_CONTACTO: 'detallesContacto',
    ADD_NOTA_CITA: 'addNotaCita'
}

const initialState = { 
    modals:{
        addCita: false,
        selectContact: false,
        addContact: false,
        detallesCita: false,
        fichaPaciente: false,
        detllesContacto: false,
        addNotaCita: false
    },
    modalBusy: false,
    modalsHistory: []
 };

 const setModalShow = (state, modal, show) => {
    state.modals = {...state.modals, [modal]:show};
    if(show){
        state.modalsHistory.push(modal);
    }
    else{
        const lastId = state.modalsHistory.length - 1;
        if(modal === state.modalsHistory[lastId]){
            state.modalsHistory.pop();
        }
    }
 }

 const setModalBusy = (state, busy) => {
    state.modalBusy = busy;
 }

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        ShowModal: (state, action) => setModalShow(state, action.payload, true),
        HideModal: (state, action) => setModalShow(state, action.payload, false),
        ModalBusy: (state, action) => setModalBusy(state, action.payload)
    }
});

export const { ShowModal, HideModal, ModalBusy } = appSlice.actions;

export default appSlice.reducer;