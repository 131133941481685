import React, { useState } from 'react';
import { Label, LeyendaError } from '../GeneralComponents/Formularios';
import "../../pages/styles/Registro.scss"
import { Input } from 'antd';

//TODO revisar/cambiar lo valores "true","false"
const InputAntd = ({ value, setStateValue, type, label, placeholder, id, textError, setStateError, valueError, expresionRegular,
	funcion, disabled, readOnly, vacio, maxLength, className, style, extras, styleLabel, styleDiv, requiredICon, mensajesError }) => {
	// console.log(valueError);
	const [MensajeError, setMensajeError] = useState(textError !== undefined ? textError[0] : "");

	const onChange = (e) => {
		// console.log(e.target.value);

		setStateValue(e.target.value);
		if (vacio !== undefined) {
			if (vacio) {
				if (setStateError !== undefined) {
					setStateError("true");
				}
			} else {
				if (id === "nacimiento") {
					if (!!e.target.value) {
						let valido = extras(e.target.value);
						if (valido) {
							setStateError("true");
						} else {
							setMensajeError(textError[1]);
							setStateError("false");
						}
					} else {
						setMensajeError(textError[0]);
						setStateError("false");
					}
				} else {
					if (e.target.value.trim().length > 0) {
						setStateError("true");
					} else {
						setMensajeError(textError[0]);
						setStateError("false");
					}
				}



			}
		} else {
			if (setStateError !== undefined) {
				if (e.target.value.trim().length > 0) {
					setStateError("true");
				}
			}

		}
		if (extras !== undefined) {
			// console.log(extras);
			if (extras[0] === "IMC") {
				let valor = e.target.value;
				if (valor.length > 0) {


					if (id === "altura") {
						if (!!extras[2]) {
							let imc = extras[2] / (valor * valor);
							extras[1](imc.toFixed(2));
						}
					} else {
						if (!!extras[2]) {
							let imc = valor / (extras[2] * extras[2]);
							extras[1](imc.toFixed(2));
						}
					}

				} else {
					extras[1]("");
				}

			}
		}

		if (id === "password") {
			extras();
		}

	}

	const onChangeSecond = (e) => {
		// console.log(e.target.value);
		if (expresionRegular !== undefined) {
			if (!expresionRegular.test(e.target.value)) {
				if (!!e.target.value) {
					if (vacio !== undefined) {
						if (vacio) {
							if (!!value) {
								mensajesError(textError[1]);
								// setMensajeError(textError[1]);
								// setStateError("false");
							} else {
								setStateError("true");
							}

						} else {
							// console.log(value);
							if (!!value) {
								mensajesError(textError[1]);
								// setMensajeError(textError[1]);
								// setStateError("false");
							} else {
								mensajesError(textError[0]);
								// setMensajeError(textError[0]);
								// setStateError("false");
							}
						}
					} else {
						// setMensajeError(textError[1]);
						// setStateError("false");
						setStateError("true");
					}
				} else {
					setStateValue("");
				}

			} else {
				setStateValue(e.target.value);
				if (id === "celular") {
					if (value.length < 10) {
						mensajesError(textError[2]);
						// setMensajeError(textError[2]);
						// setStateError("false");
					} else {
						setStateError("true");

					}
				} else if (id === "tarjeta") {
					if (value.length < 16) {
						mensajesError(textError[2]);
						// setMensajeError(textError[2]);
						// setStateError("false");
					} else {
						setStateError("true");
					}
				} else if (id === "codigo") {
					if (value.length < 4) {
						mensajesError(textError[2]);
						// setMensajeError(textError[2]);
						// setStateError("false");
					} else {
						setStateError("true");
					}
				} else if (id === "cedula") {
					if (value.length < 4) {
						mensajesError(textError[2]);
						// setStateError("false");
					} else {
						setStateError("true");
					}
				} else {
					setStateError("true");
				}
			}
		} else {
			setStateValue(e.target.value);
			if (vacio !== undefined) {
				if (vacio) {
					if (setStateError !== undefined) {
						setStateError("true");
					}
				} else {
					if (id === "nacimiento") {
						if (!!e.target.value) {
							let valido = extras(e.target.value);
							if (valido) {
								setStateError("true");
							} else {
								mensajesError(textError[1]);
								// setMensajeError(textError[1]);
								// setStateError("false");
							}
						} else {
							mensajesError(textError[0]);
							// setMensajeError(textError[0]);
							// setStateError("false");
						}
					} else {
						if (e.target.value.trim().length > 0) {
							setStateError("true");
						} else {
							mensajesError(textError[0]);
							// setMensajeError(textError[0]);
							// setStateError("false");
						}
					}



				}
			} else {
				if (setStateError !== undefined) {
					if (e.target.value.trim().length > 0) {
						setStateError("true");
					}
				}

			}
		}

	}

	const validacion = (e) => {
		// console.log(expresionRegular);
		if (expresionRegular) {
			// console.log(expresionRegular.test(e.target.value));
			if (expresionRegular.test(e.target.value)) {
				// setStateValue(va);
				if (id === "celular") {
					if (value.length < 10) {
						setMensajeError(textError[2]);
						setStateError("false");
					} else {
						setStateError("true");

					}
				} else if (id === "tarjeta") {
					if (value.length < 16) {
						setMensajeError(textError[2]);
						setStateError("false");
					} else {
						setStateError("true");
					}
				} else if (id === "codigo") {
					if (value.length < 4) {
						setMensajeError(textError[2]);
						setStateError("false");
					} else {
						setStateError("true");
					}
				} else if (id === "cedula") {
					if (value.length < 4) {
						setMensajeError(textError[2]);
						setStateError("false");
					} else {
						setStateError("true");
					}
				} else {
					setStateError("true");
				}

			} else {
				// console.log("aqui mal");
				if (vacio !== undefined) {

					if (vacio) {
						if (!!value) {
							setMensajeError(textError[1]);
							setStateError("false");
						} else {
							setStateError("true");
						}

					} else {
						// console.log(value);
						if (!!value) {
							setMensajeError(textError[1]);
							setStateError("false");
						} else {
							setMensajeError(textError[0]);
							setStateError("false");
						}
					}
				} else {
					// setMensajeError(textError[1]);
					// setStateError("false");
					setStateError("true");
				}

			}
		}
	}

	return (
		<div style={styleDiv}>
			{(label !== undefined && !!label) && (<Label style={styleLabel} valido={valueError}>{vacio !== undefined ? (!vacio && requiredICon) && <b style={{ color: "#ff4d4f", fontSize: "14px", fontFamily: "SimSun,sans-serif", marginInlineEnd: "4px" }}>*</b> : ""}{label}</Label>)}
			<Input
				style={style}
				className={className}
				type={type}
				placeholder={placeholder}
				id={id}
				value={value}
				onChange={onChange}
				// onChange={onChangeSecond}
				onKeyUp={validacion}
				disabled={disabled !== undefined ? disabled ? true : false : false}
				readOnly={readOnly ? true : false}
				maxLength={!!maxLength ? maxLength : ""}
				status={valueError !== undefined ? valueError === 'true' ? "" : "error" : ""}
			// {temp ? disabled : enable}
			/>
			<LeyendaError valido={valueError}>{MensajeError}</LeyendaError>

		</div>
	);
}

export default InputAntd;