import { useDispatch, useSelector } from "react-redux";
import "./GenaralStyles/ModalDetalle.scss";
import CloseIcon from "@mui/icons-material/Close";
import { Button } from "antd";

import { HideModal } from "../../redux/appSlice";
import { useState } from "react";

const ModalBase = ({ Title, children, isOpen, styleDet, optionClose, showBackground=true, BackgroundColor='rgba(0, 0, 0, 0.6)', modalId, PreventClose=false }) => {
  const [showCloseAlert, setShowCloseAlert] = useState(false);
  const [title, setTitle] = useState(Title);

  const dispatch = useDispatch();
  const { modalBusy } = useSelector((state)=>state.app);
  
  const handleModalContainerClick = e => e.stopPropagation();

  const handleClose = () => {
    console.log(optionClose)
    if (optionClose || optionClose === undefined) {
      if(modalBusy) return;
      console.log(PreventClose)
      if(!PreventClose){
        if(modalId){
            console.log('closing: ' + modalId)
            dispatch(HideModal(modalId));
        }
      }
      else{
        handlePreventClose();
      }
    }
  }

  const handlePreventClose = () => {
    setTitle('¡Advertencia!');
    setShowCloseAlert(true);
  }

  const preventCancel = () => {
    setTitle(Title);
    setShowCloseAlert(false);
  }

  const renderCloseIcon = () => {
    if(!showCloseAlert && optionClose){
      return(
        <CloseIcon
            style={{ fontSize: 20, cursor: "pointer" }}
            onClick={()=>handleClose()}
        />
      )
    }
  }

  const renderCloseAlert = () => {
    return(
      <div className="ModalListContactosConsult" style={{ padding: "2%" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "10px 0",
          }}
        >
          <b>¿Desea regresar a la vista anterior?</b>
        </div>
        <div style={{ display: "flex", gap: "1%" }}>
          <Button
            style={{
              width: "100%",
              background: "#148f9f",
              borderColor: "#148f9f",
              color: "white",
            }}
            size="sm"
            onClick={() => preventCancel()}
          >
            Cancelar
          </Button>
          <Button
            style={{
              width: "100%",
              background: "#148f9f",
              borderColor: "#148f9f",
              color: "white",
            }}
            size="sm"
            onClick={() => {
              // cleanInputsFicha();
              // ClosedAlertConfirm(true);
              // openModalDetalle(true);
            }}
          >
            Atrás
          </Button>
          <Button
            style={{
              width: "100%",
              background: "#148f9f",
              borderColor: "#148f9f",
              color: "white",
            }}
            size="sm"
            onClick={() => {
              // if (!!Sexo && !!Nacimiento) {
              //   ClosedAlertConfirm(true);
              //   openFichaContacto(true);
              //   addFichaContacto();
              // } else {
              //   ClosedAlertConfirm(true);
              //   openFichaContacto(true);
              //   if (!!!Sexo) {
              //     setMensajeSexo("false");
              //   }
              //   if (!!!Nacimiento) {
              //     setMensajeNacimiento("false");
              //   }

              //   setOpen(true);
              //   setTypeMessage("warning");
              //   setMessage("Faltan campos por completar");
              // }
            }}
          >
            Atrás y guardar
          </Button>
        </div>
      </div>
    )
  }

  return (
    <div onClick={() => handleClose()} className={isOpen ? "modalDetalle is-open" : "modalDetalle"} style={{backgroundColor:BackgroundColor}}>
      <div className={`modalDetalle is-open`}>
        <div style={styleDet} className="modalDetalle-container" onClick={handleModalContainerClick}>
          <div className="TopCitasModal">
              <p>{title}</p>{" "}
              { renderCloseIcon() }
          </div>
          {showCloseAlert? renderCloseAlert():children}
        </div>
      </div>
    </div>
  )
}

export default ModalBase;