import React, { useEffect, useState } from 'react';
import ReactGA from "react-ga4";
import { Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import ContactList from '../ContactList';

import { chars } from '../../../utils/utils.js';
import ModalBase from '../../GeneralComponents/ModalDetalle';
import AddContactContent from './AddContact';
import { UseModal } from '../../../hooks/UseModal';
import { ModalScreen, ShowModal, HideModal } from '../../../redux/appSlice';
import useContactosData from '../../../hooks/Query/useContactosData';
import { Loader } from '../../GeneralComponents/Loader';
import LoaderModals from '../../GeneralComponents/Loader_modals';

const SelectContactoContent = ({modalId, FilterTypeContact}) => {
    const [contactListBase, setContactListBase] = useState([]);
    const [contactList, setContactList] = useState([]);

    const { modals } = useSelector((state)=>state.app);
    const dispatch = useDispatch();

    const Contactos = useContactosData();

    useEffect(()=>{
        let resultContactos = Contactos.data;

        if(FilterTypeContact && FilterTypeContact.length >0){
            resultContactos = Contactos.data.filter(contacto=>FilterTypeContact.includes(contacto.Type));
        }

        setContactListBase(resultContactos);
        setContactList(resultContactos);
    }, [Contactos.data]);

    const filterContacts = (text) => {
        var expr = /[áàéèíìóòúùñ]/gi;
        let filter = [];
        
        if(text.length === 1){
            setContactList(contactListBase);
            return;
        }

        contactListBase.forEach((contacto) => {
          if (!!contacto.Celular) {
            if (`${contacto.Nombre} ${contacto.ApellidoPaterno}${!!contacto.ApellidoMaterno ? !!contacto.ApellidoMaterno.trim() ? ` ${contacto.ApellidoMaterno}` : "" : ""}`.replace(expr, function (e) { return chars[e]; }).toLowerCase().indexOf(text.replace(expr, function (e) { return chars[e]; }).toLowerCase()) > -1 ||
              contacto.ApellidoPaterno.replace(expr, function (e) { return chars[e]; }).toLowerCase().indexOf(text.replace(expr, function (e) { return chars[e]; }).toLowerCase()) > -1 ||
              contacto.ApellidoMaterno.replace(expr, function (e) { return chars[e]; }).toLowerCase().indexOf(text.replace(expr, function (e) { return chars[e]; }).toLowerCase()) > -1 ||
              contacto.Celular.replace(expr, function (e) { return chars[e]; }).toLowerCase().indexOf(text.replace(expr, function (e) { return chars[e]; }).toLowerCase()) > -1) {
    
              filter.push(contacto);
            }
          } else {
            if (`${contacto.Nombre} ${contacto.ApellidoPaterno} ${!!contacto.ApellidoMaterno ? !!contacto.ApellidoMaterno.trim() ? ` ${contacto.ApellidoMaterno}` : "" : ""}`.replace(expr, function (e) { return chars[e]; }).toLowerCase().indexOf(text.replace(expr, function (e) { return chars[e]; }).toLowerCase()) > -1 ||
              contacto.ApellidoPaterno.replace(expr, function (e) { return chars[e]; }).toLowerCase().indexOf(text.replace(expr, function (e) { return chars[e]; }).toLowerCase()) > -1 ||
              contacto.ApellidoMaterno.replace(expr, function (e) { return chars[e]; }).toLowerCase().indexOf(text.replace(expr, function (e) { return chars[e]; }).toLowerCase()) > -1) {
    
              filter.push(contacto);
            }
          }
        });
        
        setContactList(filter);
    }

    return(
        
        <div className="scrolMotivoConsulta">
            {Contactos.isFetching ? <LoaderModals className={'ModalAddCita'}/>:
                <>
                    <div style={{ display: "flex", justifyContent: "right", margin: "1%" }}>
                        <Button
                            type="button"
                            onClick={() => {
                                ReactGA.event({
                                    category: "Citas",
                                    action: "Nuevo_contacto",
                                    label: "Boton para abrir form de contacto nuevo", // optional
                                    value: 99, // optional, must be a number
                                    nonInteraction: true, // optional, true/false
                                    transport: "xhr", // optional, beacon/xhr/image
                                });
                                dispatch(ShowModal(ModalScreen.ADD_CONTACT))
                            }}
                            style={{
                            width: "150px",
                            backgroundColor: "#148f9f",
                            color: "white",
                            }}
                        >
                            Nuevo paciente
                        </Button>
                    </div>
                    <div className="ModalAddCita">
                        <form action="" onSubmit={() => { }} style={{ padding: "0" }}>
                            <div className="Buscar">
                            <p>Buscar:</p>
                            <input
                                style={{ width: "80%" }}
                                type="text"
                                className="inputblue"
                                placeholder="Escribe nombre o celular"
                                onChange={(e)=>filterContacts(e.target.value)}
                            />
                            </div>
                            <br />
                            <p>Contacto:</p>

                            <ContactList
                                contactList={contactList}
                                modalId={modalId}
                            />
                        </form>
                    </div>
                </>
            }
            
        </div>
    )
}

export default SelectContactoContent;