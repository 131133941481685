import React, { useEffect, useState } from "react";
// import * as React from "react";
// import "antd/dist/antd.css";
import { Button, Input, Empty, Row } from "antd";
import ButtonAntd from "../componentes/GeneralComponents/ButtonAntd";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Agregar from ".././Img/Iconos/AgregarBlanco.svg";
import CloseIcon from '@mui/icons-material/Close';
//impotar iconos
import iconos from "../componentes/IconosDeSitio";

/*Importando Componente de modal*/
import ModalSelectContact from "../componentes/GeneralComponents/ModalDetalle";
import ModalDetalle from "../componentes/GeneralComponents/ModalDetalle";

// Importando modal de detalle
import Modal from "../componentes/GeneralComponents/ModalDetalle";

// impotar componentes
import VinculacionCard from "../componentes/Vinculacion/VinculacionCard";
import ModalAddvincul from "../componentes/Vinculacion/ModalAddVinculacion";
// importar scss
import "./styles/Vinculacion.scss";

// importando metodo de modal
import { UseModal } from "../hooks/UseModal";
import { Loader } from "../componentes/GeneralComponents/Loader";

import {
  getSolicitudes_request,
  solicitudDetail_request,
  acceptSolicitud_request,
  getUsersToAdd_request,
  cancelSolicitud_request,
  sendSolicitud_request,
} from "../services/vinculacion_services";
import NavbarNuevo from "../componentes/GeneralComponents/Navbar_Nuevo";
import { Content } from "antd/es/layout/layout";
import DetailVinculacion from "../componentes/Vinculacion/detailVinculacion";
import { getAuthFirebase_request } from "../services/notificaciones_services";
import { useSelector } from "react-redux";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Vinculacion() {
  const userInfo = useSelector((state) => state.userInfo.usuario);

  // useState del modal
  const [isOpenDetalleVinc, OpenDetalleVinc, CloseDetalleVinc] =
    UseModal(false);
  const styleDet = {
    Height: "calc(100vh - 52px)",
    Width: "calc(100vw - 52px)",
  };

  // variable del metodo de modal
  const [isOpenModal1, openModal1, CloseModal1] = UseModal(false);
  const [solicitudes, setSolicitudes] = useState([]);
  const [solicitudesSearch, setSolicitudesSearch] = useState([]);
  const [infoSolicitud, setInfoSolicitud] = useState("");
  const [usersToAdd, setUsersToAdd] = useState([]);
  const [usersToAddFilter, setUsersToAddFilter] = useState([]);
  const [loader, setLoader] = useState(false);
  const [loaderModal, setLoaderModal] = useState(false);

  const [open, setOpen] = useState(false);
  const [typeMessage, setTypeMessage] = useState("");
  const [message, setMessage] = useState("");
  const [search, setSearch] = useState("");

  // ******** FUNCIONES ********
  async function getSolicitudes() {
    setLoader(true);
    let response = await getSolicitudes_request();
    if (response.ok) {
      // console.log(response.data);
      setSolicitudes(response.data);
      setSolicitudesSearch(response.data);
    } else {
      setOpen(true);
      setTypeMessage("error");
      setMessage(response.mensaje);
    }
    setLoader(false);
    return;
  }

  const detalleSolicitud = async (idSolicitud) => {
    setLoaderModal(true);
    // console.log(idSolicitud);
    let response = await solicitudDetail_request(idSolicitud);
    // console.log(response);
    if (response.ok) {
      console.log(response.data);
      setInfoSolicitud(response.data);
    } else {
      setOpen(true);
      setTypeMessage("error");
      setMessage(response.mensaje);
    }
    setLoaderModal(false);
  };

  async function getUsersToAdd() {
    let response = await getUsersToAdd_request();
    if (response.ok) {
      // console.log(response.data);
      setUsersToAdd(response.data);
      setUsersToAddFilter(response.data);
    } else {
      // setOpen(true);
      // setTypeMessage("error");
      // setMessage(response.mensaje);
    }
  }

  async function sendSolicitudes(idContacto) {
    let response = await sendSolicitud_request(idContacto);
    if (response.ok) {
      // console.log(response.data);
      setOpen(true);
      setTypeMessage("success");
      setMessage("Vinculación enviada");
      getSolicitudes();
    } else {
      setOpen(true);
      setTypeMessage("error");
      setMessage(response.mensaje);
    }
  }

  function filterList(text) {
    var chars = {
      á: "a",
      é: "e",
      í: "i",
      ó: "o",
      ú: "u",
      à: "a",
      è: "e",
      ì: "i",
      ò: "o",
      ù: "u",
      ñ: "n",
      Á: "A",
      É: "E",
      Í: "I",
      Ó: "O",
      Ú: "U",
      À: "A",
      È: "E",
      Ì: "I",
      Ò: "O",
      Ù: "U",
      Ñ: "N",
    };
    var expr = /[áàéèíìóòúùñ]/gi;
    let filter = [];
    solicitudesSearch.forEach((solicitud) => {
      // console.log(solicitud.Usuario.toLowerCase(text));
      if (
        solicitud.Usuario.replace(expr, function (e) {
          return chars[e];
        })
          .toLowerCase()
          .indexOf(text.target.value.toLowerCase()) > -1 ||
        solicitud.Tipo.replace(expr, function (e) {
          return chars[e];
        })
          .toLowerCase()
          .indexOf(text.target.value.toLowerCase()) > -1
      ) {
        filter.push(solicitud);
        // console.log(solicitud);
      }
    });
    // console.log(filter);

    setSolicitudes(filter);
  }

  function filterListContacts(text) {
    var chars = {
      á: "a",
      é: "e",
      í: "i",
      ó: "o",
      ú: "u",
      à: "a",
      è: "e",
      ì: "i",
      ò: "o",
      ù: "u",
      ñ: "n",
      Á: "A",
      É: "E",
      Í: "I",
      Ó: "O",
      Ú: "U",
      À: "A",
      È: "E",
      Ì: "I",
      Ò: "O",
      Ù: "U",
      Ñ: "N",
    };
    var expr = /[áàéèíìóòúùñ]/gi;
    let filter = [];
    usersToAddFilter.forEach((user) => {
      // console.log(solicitud.Usuario.toLowerCase(text));
      if (
        user.nombre
          .replace(expr, function (e) {
            return chars[e];
          })
          .toLowerCase()
          .indexOf(text.target.value.toLowerCase()) > -1 ||
        user.correo
          .replace(expr, function (e) {
            return chars[e];
          })
          .toLowerCase()
          .indexOf(text.target.value.toLowerCase()) > -1
      ) {
        filter.push(user);
        // console.log(solicitud);
      }
    });
    // console.log(filter);

    setUsersToAdd(filter);
  }

  async function readModule() {
    await getAuthFirebase_request({ accion: 1, modulo: "vinculacion", userId: userInfo.id });
  }

  // console.log(document.getElementById("solicitudCard"));

  // if (document.getElementById("solicitudCard") != null) {
  //   document.getElementById("solicitudCard").addEventListener("click", function (e) {
  //     e.preventDefault();
  //     // console.log(document.getElementById("solicitud").dataset.id);

  //     // setIdContacto(document.getElementById("solicitud").dataset.id);
  //     getSolicitudesDetail(document.getElementById("solicitudCard").dataset.id);

  //   });
  // }

  useEffect(() => {
    readModule();
    getSolicitudes();
    getUsersToAdd();
  }, []);

  // console.log(idContacto);

  return (
    <>
      {loader ? (
        <Loader Loading={loader} />
      ) : (
        <Stack>
          <NavbarNuevo
            mensajesError={{
              setOpen: setOpen,
              setTypeMessage: setTypeMessage,
              setMessage: setMessage,
            }}
            modulo={"vinculacion"}
            searchBar={
              <div className="ContEncabezadovincul">
                <div className="Encabesado-vinculacion">
                  <div>
                    <b>Vinculación</b>
                  </div>
                  <ButtonAntd
                    tipo={1}
                    style={{ width: "100px", color: "white" }}
                    onClick={openModal1}
                    children={
                      <Row style={{ gap: ".5rem", justifyContent: "space-between", alignItems: "center" }}>
                        Nueva
                        <img style={{ width: "12px" }} src={Agregar} alt="" />
                      </Row>
                    }
                  />
                </div>
                <Input
                  style={{ width: "31%" }}
                  className="inputblue"
                  placeholder="Buscar..."
                  type="text"
                  onChange={filterList}
                />
              </div>
            }
          />

          <Content
            className="site-layout"
            style={{
              padding: "0 3%",
              width: "100%",
              overflow: "initial",
            }}
          >
            {solicitudes.length !== 0 ? (
              <div style={{ display: "block" }}>
                <VinculacionCard
                  solicitudes={solicitudes}
                  detalleSolicitud={detalleSolicitud}
                  OpenDetalleVinc={OpenDetalleVinc}
                />
              </div>
            ) : (
              <div>
                <Empty description={<span style={{ color: "black" }}> No hay solicitudes</span>} />
              </div>
            )}
          </Content>

          <ModalSelectContact
            isOpen={isOpenModal1}
            closeModal={CloseModal1}
            styleDet={styleDet}
            optionClose={true}
          >
            <div>
              <div
                style={{
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                  background: "#148f9f",
                  padding: "1% 2%",
                }}
              >

                <p>Agregar contacto</p>{" "}
                <CloseIcon
                  style={{ fontSize: 20, cursor: "pointer" }}
                  onClick={CloseModal1}
                />
              </div>
              <div className="ModalwidthVincu" style={{ padding: "1%" }}>
                <p>Buscar usuario</p>
                <Input
                  placeholder="Escribe nombre o correo de usuario"
                  type="search"
                  style={{ width: "100%" }}
                  onChange={filterListContacts}
                />
                <hr style={{ marginTop: "3%" }} />
                <br />
                <div className="contCardModal">
                  <ModalAddvincul
                    usersToAdd={usersToAdd}
                    sendSolicitudes={sendSolicitudes}
                    closeModal={CloseModal1}
                  />
                </div>
              </div>
            </div>
          </ModalSelectContact>

          {/*
            TIPOS DE USUARIO
            Tipo 1 = Medico
            Tipo 3 = Paciente
            Tipo 4 = Asistente
            Tipo 5 = Familiar
          */}

          <DetailVinculacion
            isOpen={isOpenDetalleVinc}
            close={CloseDetalleVinc}
            style={styleDet}
            optionClose={true}
            infoSolicitud={infoSolicitud}
            setInfoSolicitud={setInfoSolicitud}
            actions={
              { getSolicitudes: getSolicitudes, }
            }
            loader={loaderModal}
            setLoader={setLoaderModal}
          />

          <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={() => setOpen(false)}
          >
            <Alert
              onClose={() => setOpen(false)}
              severity={typeMessage}
              sx={{ width: "100%" }}
            >
              {message}
            </Alert>
          </Snackbar>
        </Stack>
      )}
    </>
  );
}
