import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import "./styles/Citas.scss";
import "./styles/Consultas.scss";




import {
  Input,
  Select,
  TimePicker,
  Row,
  Button,
  Calendar,
  Layout,
  DatePicker,
} from "antd";
import NavbarNuevo from "../componentes/GeneralComponents/Navbar_Nuevo";
import { Loader } from "../componentes/GeneralComponents/Loader";
import ButtonAntd from "../componentes/GeneralComponents/ButtonAntd";

import { UseModal } from "../hooks/UseModal";

import ModalBase from '../componentes/GeneralComponents/ModalBase';
import Confi from "../Img/Iconos/Configuracion.svg";
import Confi_white from "../Img/Iconos/Configuracion_white.svg";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {
  BorderColor,
  Cancel,
  CancelOutlined,
  CancelRounded,
  CheckCircleOutline,
  Edit,
  Search,
  AddOutlined,
  EditOutlined,
  DeleteForeverOutlined
} from "@mui/icons-material";

import {
  getHorarios_request,
  getConfigCitas_request,
  getCitasFilter_request,
} from "../services/citas_services";
import {
  getContacts_request,
} from "../services/contactos_services";
import {
  getEstado_request,
  getLocalidad_request,
} from "../services/expediente_service";

import dayjs from "dayjs";
import "dayjs/locale/es";
import locale from "antd/es/calendar/locale/es_ES";

import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useSelector, useDispatch } from 'react-redux';

import ReactGA from "react-ga4";
import moment from 'moment';
import { showSnackbar } from "../redux/snackbarSlice";
import { getAuthFirebase_request } from "../services/notificaciones_services";

import { chars, getCalendarRange } from '../utils/utils.js'
import CalendarPage from "../componentes/GeneralComponents/googleCalendar";
import { SetFechaInicial, SetFechaFinal, SetCalendarDate, SelectCitaDate, SetReloadCitas, SetCitaId } from '../redux/citas';
import { SetContacts, SelectContact } from '../redux/contactos.js';
import { ShowModal, ModalScreen } from '../redux/appSlice.js';
import { SetHorario } from "../redux/horariosAtencion.js";

import AddCitasContent from "../componentes/Citas/ModalContents/AddCitas.js";
import SelectContactoContent from "../componentes/Citas/ModalContents/SelectContacto.js";
import AddContactContent from "../componentes/Citas/ModalContents/AddContact.js";
import DetallesCitaContent from "../componentes/Citas/ModalContents/DetallesCita.js";
import FichaPacienteContent from "../componentes/Citas/ModalContents/FichaPaciente.js";
import DetallesContactoContent from "../componentes/Citas/ModalContents/DetallesContacto.js";
import AddNotaCitaContent from "../componentes/Citas/ModalContents/AddNotaCita.js";
import useContactosData from "../hooks/Query/useContactosData.js";
import LoaderCircle from "../componentes/GeneralComponents/LoaderCircle.js";
import useCitasData from "../hooks/Query/useCitasData.js";


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const { Header, Content, Footer } = Layout;
const { RangePicker } = DatePicker;

export const TiposCita = [
  'Urgencia',
  'Primera vez',
  'Seguimiento'
]

function Citas() {
  const dispatch = useDispatch();
  const location = useLocation();

  const userInfo = useSelector((state) => state.userInfo.usuario);
  const { fechaInicio, fechaFinal, reloadCitas } = useSelector((state) => state.appointments);
  const { modals } = useSelector((state) => state.app);

  const contactos = useContactosData()
  
  let fecha_format = new Date(Date.now());
  let mes = fecha_format.getMonth() + 1;
  let mes_correcto = mes.toString().length === 1 ? `0${mes}` : mes;
  let fecha_hoy =
    fecha_format.getFullYear() +
    "-" +
    mes_correcto +
    "-" +
    fecha_format.getDate();

  const styleDet = {
    height: "clac(100vh - 52px)",
    width: "clac(100vw - 52px)",
    margin: "0 2%",
  };

  const styleFichaContacto = {
    height: "clac(100vh - 52px)",
    width: "clac(100vw - 52px)",
    margin: "0 2%",
  };

  const styleCancelMotivo = {
    height: "clac(100vh - 52px)",
    width: "clac(100vw - 52px)",
    margin: "0 2%",
  };

  const navigate = useNavigate();

  const [horarios, setHorarios] = useState([]);
  const [listCitasOcupadas, setListCitasOcupadas] = useState([]);
  const [listHorariosDisponibles, setListHorariosDisponibles] = useState([]);

  const [DatosContacto, setDatosContacto] = useState({
    id: "",
    nombre: "",
    imagen: "",
  });
  const [loader, setLoader] = useState(true);
  const [loaderDetalle, setLoaderDetalle] = useState(false);

  const ConfigCitas = useContactosData();
  const Citas = useCitasData();

  const StatusIcons = [
    <CheckCircleOutline color="disabled" />,
    <CheckCircleOutline color="warning" />,
    <CancelOutlined color="error" />,
    <CheckCircleOutline color="success" />
  ];

  useEffect(() => {
    const loadData = async() =>{
      readModule();
      let startMonth = dayjs(fechaInicio).format('YYYY-MM-D');
      let endMonth = dayjs(fechaFinal).format('YYYY-MM-D');

      if(!fechaInicio && !fechaFinal){
        const calendarRange = getCalendarRange();

        dispatch(SetFechaInicial(calendarRange[0]));
        dispatch(SetFechaFinal(calendarRange[1]));
        dispatch(SetCalendarDate(new Date().toISOString()));

        startMonth = dayjs(calendarRange[0]).format('YYYY-MM-D');
        endMonth = dayjs(calendarRange[1]).format('YYYY-MM-D');
        Citas.refetch();
      }
      
      setLoader(false);
    }
    
    if (!!!location.state) {
    } else {
      if (!!location.state.idCita) {
        setDatosContacto({
          id: location.state.id,
          imagen: location.state.imagen,
          nombre: location.state.nombre,
        });
        detailCita(location.state.idCita, 1);
      }
    }

      loadData();
  }, []);

  async function getCitasDisponibles(date, hours) {
    setLoaderDetalle(true);
    let data = new FormData();
    let horarios_disponibles = [];

    data.append("FechaInicio", date);
    data.append("FechaFin", date);

    let response = await getCitasFilter_request(4, data);
    if (response.ok) {
      response.data.forEach((cita) => {
        let hora = `${cita.HoraCita.substring(0, 1) === "0"
          ? cita.HoraCita.substring(1, 5)
          : cita.HoraCita.substring(0, 5)
          } ${cita.HoraCita.substring(
            cita.HoraCita.length - 5,
            cita.HoraCita.length
          ) === "a. m."
            ? "a.m"
            : "p.m"
          }`;
        hours.Horario.forEach((horario) => {
          if (hora !== horario) {
            horarios_disponibles.push(horario);
          }
        });
      });
      setListCitasOcupadas(response.data);
      setListHorariosDisponibles(horarios_disponibles);
    } else {
      setListCitasOcupadas([]);
      if (hours.Horario.length > 0) {
        hours.Horario.forEach((horario) => {
          horarios_disponibles.push(horario);
        });
        setListHorariosDisponibles(horarios_disponibles);
      }

    }
    setLoaderDetalle(false);
    return;
  }

  function getContactDetails(idContact){
    return contactos.data.find(contact => contact.IdContacto === idContact);
  }

  async function detailCita(id, idContact, option) {    
    const contact = getContactDetails(idContact);
    
    if(!contact) return;
    
    dispatch(SetCitaId(id))
    dispatch(SelectContact({ id: contact.IdContacto, nombre: `${contact.Nombre} ${contact.ApellidoPaterno} ${contact.ApellidoMaterno}`, imagen: contact.Imagen_url }));
    dispatch(ShowModal(ModalScreen.DETALLE_CITA));    
  }

  async function getHorarios(fecha) {
    setLoaderDetalle(true);

    let data = { Fecha: fecha };
    let response = await getHorarios_request(data);
    if (response.ok) {
      dispatch(showSnackbar({
        show: true,
        text: 'Horarios existentes',
        tpe: 'success'
      }));
      let array = [];
      response.data.forEach((element) => {
        array.push({ value: element, label: element });
      });
      setHorarios(array);
      getCitasDisponibles(fecha, { Horario: response.data });
    } else {
      setHorarios([]);
      getCitasDisponibles(fecha, { Horario: [] });
      dispatch(showSnackbar({
        show: true,
        text: response.mensaje,
        tpe: 'error'
      }));
    }
    setLoaderDetalle(false);
    return;
  }

  async function readModule() {
    await getAuthFirebase_request({ accion: 1, modulo: "citas", userId: userInfo.id });
  }

  const nuevaCitaHandler = (fecha) => {
    dispatch(SelectCitaDate(fecha));

    // TODO checar data, podria ser funcion?
    ReactGA.event({
      category: "Citas",
      action: "Nueva_cita",
      label: "Boton para abrir form de cita nueva", // optional
      value: 99, // optional, must be a number
      nonInteraction: true, // optional, true/false
      transport: "xhr", // optional, beacon/xhr/image
    });

    if (ConfigCitas.data.length > 0) {
      getHorarios(fecha);
      dispatch(ShowModal('addCita'));
    } else {
      dispatch({
        show: true,
        text: 'Configure un horario, en caso de ya tenerlo recarge la página',
        type: 'warning'
      })
    }
  }

  const reloadCalendar = async() => {
    Citas.refetch();
  }

  return (
    <Stack>
      <NavbarNuevo
        modulo="Citas"
        detalle={detailCita}
        searchBar={
          <>
            <div className="Cebecera">
              <div className="Encabesado">
                <div>
                  <b>Citas</b>
                </div>
                <div
                  style={{
                    color: "#148f9f",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img
                    onClick={() => {
                      navigate("/app/CitasConfig");
                    }}
                    style={{ width: "20px" }}
                    src={Confi}
                    alt=""
                  />
                  &nbsp;&nbsp;
                  <ButtonAntd
                    tipo={1}
                    style={{ width: "100px" }}
                    onClick={() => nuevaCitaHandler(fecha_hoy)}
                    children={<Row style={{ width: "100%", display: "flex", color: "white", justifyContent: "space-between", alignItems: "center" }}>
                      Nueva <AddCircleIcon style={{ fontSize: 15 }} />
                    </Row>
                    }
                  />
                </div>
              </div>

              <div style={{ display: "flex", gap: "1rem", marginTop: "1%" }}>
                <Row style={{ alignItems: "center" }}>
                  <CheckCircleOutline color="disabled" />
                  Pendiente
                </Row>
                <Row style={{ alignItems: "center" }}>
                  <CheckCircleOutline color="warning" />
                  Confirmada
                </Row>
                <Row style={{ alignItems: "center" }}>
                  <CheckCircleOutline color="success" /> Atendida
                </Row>
                <Row style={{ alignItems: "center" }}>
                  <CancelOutlined color="error" /> Cancelada
                </Row>
                { !Citas.isLoading && Citas.isFetching?
                  <Row style={{ alignItems: "center" }}>
                    <LoaderCircle Width={32} Height={32} />
                  </Row> 
                  :<></>
                }
              </div>              
            </div>
          </>
        }
      />

      <Content
        className="site-layout"
        style={{
          padding: "0 3%",
          width: "100%",
          overflow: "initial",
          backgroundColor: 'white'
        }}
      >
        {loader || Citas.isLoading ? (
          <Loader />
        ) : (
          <>
            <div style={{width: "100%"}}>
              <CalendarPage ClickCalendarCallback={(date)=>nuevaCitaHandler(date)} ClickEventCallback={detailCita} citas={Citas.data} ChangeRangeCallback={reloadCalendar} Icons={StatusIcons}/>
            </div>
          </>
        )}
      </Content>


      { modals.detallesCita &&
        <ModalBase Title='Detalle de cita' isOpen={modals.detallesCita} optionClose={true} modalId={ModalScreen.DETALLE_CITA}>
          <DetallesCitaContent />
        </ModalBase>
      }

      { modals.addCita && 
        <ModalBase Title='Nueva cita' isOpen={modals.addCita} optionClose={true} modalId={ModalScreen.ADD_CITA} >
          <AddCitasContent />
        </ModalBase>
      }
      
      { modals.selectContact &&
        <ModalBase Title='Nueva cita' styleDet={styleDet} isOpen={modals.selectContact} optionClose={true} BackgroundColor="" modalId={ModalScreen.SELECT_CONTACT}>
          <SelectContactoContent modalId={ModalScreen.SELECT_CONTACT} FilterTypeContact={[3,5]}/>
        </ModalBase>
      }
      
      { modals.addContact &&
        <ModalBase Title='Nuevo paciente' isOpen={modals.addContact} optionClose={true} modalId={ModalScreen.ADD_CONTACT}>
          <AddContactContent Type={0} PrevModal={ModalScreen.SELECT_CONTACT}/>
        </ModalBase>
      }

      { modals.fichaPaciente &&
        <ModalBase Title='Ficha de información' isOpen={modals.fichaPaciente} optionClose={true} modalId={ModalScreen.FICHA_PACIENTE}>
          <FichaPacienteContent />
        </ModalBase>
      }

      { modals.detallesContacto &&
        <ModalBase Title='Detalle de contacto' isOpen={modals.detallesContacto} optionClose={true} modalId={ModalScreen.DETALLE_CONTACTO}>
          <DetallesContactoContent />
        </ModalBase>
      }

      { modals.addNotaCita &&
        <ModalBase Title='Datos de consulta' isOpen={modals.addNotaCita} optionClose={true} modalId={ModalScreen.ADD_NOTA_CITA}>
          <AddNotaCitaContent />
        </ModalBase>
      }
    </Stack>
  );
}

export default Citas;
