import React, { useState } from "react";
import { Image, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Imag from '../../../Img/no-image.png';
import SelectAntd from "../../GeneralComponents/SelectAntd";
import TextAreaAntd from "../../GeneralComponents/TextAreaAntd";

import { showSnackbar } from "../../../redux/snackbarSlice";
import { ModalScreen, HideModal } from "../../../redux/appSlice";

const AddNotaCitaContent = () => {
    const [MotivoConsulta, setMotivoConsulta] = useState("");
    const [TipoConsulta, setTipoConsulta] = useState(null);
    const [MensajeMotivoConsulta, setMensajeMotivoConsulta] = useState("true");
    const [MensajeTipoConsulta, setMensajeTipoConsulta] = useState("true");

    const dispatch = useDispatch();
    const { selectedContact } = useSelector((state)=>state.contacts);
    const { selectedCitaId } = useSelector((state)=>state.appointments);
    const navigate = useNavigate();

    const goToConsulta = () => {
        if (!!TipoConsulta) {
            // CloseModalAddNota(true);
            dispatch(HideModal(ModalScreen.ADD_NOTA_CITA));
            dispatch(HideModal(ModalScreen.DETALLE_CITA));
            navigate("/app/NuevaConsulta", {
                state: {
                    id: selectedContact.id,
                    imagen: selectedContact.imagen,
                    nombre: selectedContact.nombre,
                    motivo: MotivoConsulta,
                    tipoConsulta: TipoConsulta,
                    idCita: selectedCitaId,
                },
            });
        } else {
            if (!!!TipoConsulta) {
                setMensajeTipoConsulta("false");
            }

            if (!!!MotivoConsulta) {
                setMensajeMotivoConsulta("false");
            }

            dispatch(showSnackbar({
                show: true,
                text: 'Faltan campos por completar',
                type: 'warning'
            }))
        }
    }

    return(
        <div className="ModalDetalleDatosConsulta">
        <div
            style={{
            display: "flex",
            justifyContent: "center",
            }}
        >
            <div
            style={{
                width: "75px",
                height: "75px",
                borderRadius: "5rem",
                overflow: "hidden",
                border: "1px solid gray",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
            id="Img-cont"
            >
            <Image
                width={80}
                src={
                !!selectedContact.imagen
                    ? "https://" + selectedContact.imagen
                    : Imag
                }
            />
            </div>
        </div>
        <div
            style={{
            display: "flex",
            justifyContent: "center",
            }}
        >
            <h4>{selectedContact.nombre}</h4>
        </div>
        <div>
            <SelectAntd
                value={TipoConsulta}
                style={{ width: "100%", margin: "2% 0" }}
                placeholder="selecciona un tipo"
                options={[
                    { value: "1", label: "Primera vez" },
                    { value: "2", label: "Seguimiento" },
                ]}
                setStateValue={setTipoConsulta}
                setStateError={setMensajeTipoConsulta}
                valueError={MensajeTipoConsulta}
                textError="Debe elegir un tipo"
            />
            <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
                style={{
                width: "100%",
                background: "#148F9F",
                border: "#148F9F",
                color: "white",
                }}
                onClick={() => goToConsulta()}
            >
                Continuar
            </Button>
            </div>
        </div>
        </div>
    )
}

export default AddNotaCitaContent;