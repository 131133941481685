import React, { useEffect, useState } from 'react';
import ReactGA from "react-ga4";
import { Button, Modal } from 'antd';
import { useDispatch } from 'react-redux';

import { addContact_request } from '../../../services/contactos_services';

import { AddContact, SelectContact } from '../../../redux/contactos';
import { showSnackbar } from '../../../redux/snackbarSlice';
import { HideModal, ModalScreen, ModalBusy } from '../../../redux/appSlice';

import LoaderModals from "../../GeneralComponents/Loader_modals";
import InputAntd from '../../GeneralComponents/InputAntd';
import TextAreaAntd from '../../GeneralComponents/TextAreaAntd';
import IconosDeSitio from '../../IconosDeSitio';
import Expresiones from '../../../utils/expresiones.js';
import useContactosData from '../../../hooks/Query/useContactosData';

const ContactType = [
    'Paciente',
    'Contacto'
]

const AddContactContent = ({Type=1, PrevModal}) => {
    const [loading, setIsLoading] = useState(false);
    const [Nombre, setNombre] = useState('');
    const [MensajeNombre, setMensajeNombre] = useState("true");
    const [ApellidoP, setApellidoP] = useState("");
    const [MensajeApellidoP, setMensajeApellidoP] = useState("true");
    const [ApellidoM, setApellidoM] = useState("");
    const [MensajeApellidoM, setMensajeApellidoM] = useState("true");
    const [Celular, setCelular] = useState("");
    const [MensajeCelular, setMensajeCelular] = useState("true");
    const [Correo, setCorreo] = useState("");
    const [MensajeCorreo, setMensajeCorreo] = useState("true");
    const [Nota, setNota] = useState("");
    const [ProfileImage, setProfileImage] = useState({
        name: "",
        url: "",
        file: "",
      });
    
    const dispatch = useDispatch();
    const contactos = useContactosData();

    useEffect(()=>{
      return() => {
        clearInputs();
      };
    }, []);

    const clearInputs = () => {
      setNombre('');
      setApellidoP('');
      setApellidoM('');
      setCelular('');
      setCorreo('');
      setNota('');
      setProfileImage({
        name: "",
        url: "",
        file: "",
      });
    }

    async function saveContact() {
      if(loading) return;
        setIsLoading(true);
        dispatch(ModalBusy(true));

        let data = new FormData();
        if (
          !!Nombre &&
          !!ApellidoP &&
          MensajeNombre !== "false" &&
          MensajeApellidoP !== "false" &&
          MensajeApellidoM !== "false" &&
          MensajeCelular !== "false" &&
          MensajeCorreo !== "false"
        ) {
          data.append("Nombre", Nombre);
          data.append("ApellidoPaterno", ApellidoP);
          data.append("ApellidoMaterno", ApellidoM);
          data.append("Nota", Nota);
          if (Celular) {
            data.append("Celular", Celular);
          }
          if (Correo) {
            data.append("Email", Correo);
          }
    
          data.append("Tipo", "Paciente");
          if (ProfileImage.file !== "") {
            data.append("Imagen", ProfileImage.file, ProfileImage.name);
          }

          let response = await addContact_request(data);

          if (response.ok) {
            ReactGA.event({
                  category: "Citas",
                  action: "Nuevo_contacto_exitoso",
                  label: "Boton para guardar contacto", // optional
                  value: 99, // optional, must be a number
                  nonInteraction: true, // optional, true/false
                  transport: "xhr", // optional, beacon/xhr/image
                });
            let contacto = {...response.data, Type: 3};
            contactos.refetch();
            dispatch(AddContact(contacto));
            dispatch(ModalBusy(false));
            let selectedContact = { 
              id: contacto.IdContacto, 
              nombre: `${contacto.Nombre} ${contacto.ApellidoPaterno} ${contacto.ApellidoMaterno}`, 
              imagen: contacto.Imagen_url 
            };
            dispatch(SelectContact(selectedContact));
            
            dispatch(HideModal(ModalScreen.ADD_CONTACT));
            dispatch(HideModal(PrevModal));

            dispatch(showSnackbar({
              show: true,
              text: "Contacto guardado",
              type: "success",
            }));

          } else {
            ReactGA.event({
              category: "Citas",
              action: "Nuevo_contacto_erroneo",
              label: `${response.mensaje}`, // optional
              value: 99, // optional, must be a number
              nonInteraction: true, // optional, true/false
              transport: "xhr", // optional, beacon/xhr/image
            });
            dispatch(showSnackbar({
              show: true,
              text: response.mensaje,
              type: "error",
            }));
          }
        } else {
          if (!Nombre) {
            setMensajeNombre("false");
          }
    
          if (!ApellidoP) {
            setMensajeApellidoP("false");
          }
          
          dispatch(showSnackbar({
            show: true,
            text: "Faltan campos por completar",
            type: "warning",
          }));
        }
    
        setIsLoading(false);
      }

    return(
        <div className="scrolMotivoConsulta">
        { loading ? (
            <LoaderModals className={"scrolMotivoConsulta"} />
        ) : (
            <div className="NuevoPacienteCitaDetall">
                <div style={{ textAlign: "center", marginBottom: "1%" }}>
                    <label htmlFor="imgPerfilConsulta">
                    <img
                        style={{
                        width: " 45px",
                        height: "45px",
                        overflow: "hidden",
                        borderRadius: "5rem",
                        objectFit: "cover",
                        objectPosition: "center",
                        border: "2px solid #d7d7d7",
                        }}
                        src={!!ProfileImage.url ? ProfileImage.url : IconosDeSitio[0]}
                        alt=""
                    />
                    <input
                        id="imgPerfilConsulta"
                        style={{ display: "none" }}
                        type="file"
                        accept="/image/x-PNG,image/jpeg"
                        onChange={(e) => {
                        const file = e.target.files[0];
                        if (file && file.type.substring(0, 5) === "image") {
                            setProfileImage({
                                name: e.target.value,
                                url: URL.createObjectURL(e.target.files[0]),
                                file: e.target.files[0],
                            });
                        }
                        }}
                    />
                    </label>
                </div>
                <br />
                <InputAntd
                    label="Nombre:"
                    requiredICon={true}
                    className="inputblue"
                    value={Nombre}
                    setStateValue={setNombre}
                    style={{ margin: "2% 0" }}
                    placeholder="Nombre"
                    setStateError={setMensajeNombre}
                    textError={[
                        "Debe escribir un nombre",
                        "Solo se permiten letras",
                    ]}
                    type="text"
                    valueError={MensajeNombre}
                    vacio={false}
                    disabled={false}
                    expresionRegular={Expresiones.Letras}
                    maxLength={40}
                />

                <InputAntd
                    label="Apellido Paterno:"
                    requiredICon={true}
                    className="inputblue"
                    value={ApellidoP}
                    setStateValue={setApellidoP}
                    style={{ margin: "2% 0" }}
                    placeholder="Apellido Paterno:"
                    setStateError={setMensajeApellidoP}
                    textError={[
                    "Debe escribir un apellido paterno",
                    "Solo se permiten letras",
                    ]}
                    type="text"
                    valueError={MensajeApellidoP}
                    vacio={false}
                    disabled={false}
                    expresionRegular={Expresiones.Letras}
                    maxLength={25}
                />
                <InputAntd
                    label="Apellido Materno:"
                    className="inputblue"
                    value={ApellidoM}
                    setStateValue={setApellidoM}
                    style={{ margin: "2% 0" }}
                    placeholder="Apellido Materno"
                    setStateError={setMensajeApellidoM}
                    textError={["", "Solo se permiten letras"]}
                    type="text"
                    valueError={MensajeApellidoM}
                    vacio={true}
                    disabled={false}
                    expresionRegular={Expresiones.Letras}
                    maxLength={25}
                />
                <InputAntd
                    label="Celular:"
                    className="inputblue"
                    value={Celular}
                    setStateValue={setCelular}
                    id="celular"
                    style={{ margin: "2% 0" }}
                    placeholder="Celular:"
                    setStateError={setMensajeCelular}
                    textError={[
                    "",
                    "Celular solo puede contener numeros",
                    "Celular debe contener 10 dígitos",
                    ]}
                    type="text"
                    valueError={MensajeCelular}
                    vacio={true}
                    disabled={false}
                    expresionRegular={Expresiones.Numeros}
                    maxLength={10}
                />

                <InputAntd
                    label="Correo:"
                    className="inputblue"
                    value={Correo}
                    setStateValue={setCorreo}
                    style={{ margin: "2% 0" }}
                    placeholder="Correo:"
                    setStateError={setMensajeCorreo}
                    textError={["", "Formato incorrecto"]}
                    type="text"
                    valueError={MensajeCorreo}
                    vacio={true}
                    disabled={false}
                    expresionRegular={Expresiones.Correo}
                />

                <TextAreaAntd
                    label="Comentarios o notas:"
                    value={Nota}
                    placeholder="Escriba un comentario o nota (opcional)"
                    style={{
                    marginBottom: ".5%",
                    fontFamily: "sans-serif",
                    }}
                    columns="30"
                    autoSize={true}
                    setStateValue={setNota}
                />

                <div>
                    <Button
                    style={{
                        width: "100%",
                        background: "#148f9f",
                        borderColor: "#148f9f",
                        color: "white",
                    }}
                    size="sm"
                    onClick={(e) => saveContact()}
                    >
                    Crear {ContactType[Type]}
                    </Button>
                </div>
            </div>
        )}
        </div>
    )
}

export default AddContactContent;