import dayjs from "dayjs";
import "dayjs/locale/es";

export const chars = {
    á: "a",
    é: "e",
    í: "i",
    ó: "o",
    ú: "u",
    à: "a",
    è: "e",
    ì: "i",
    ò: "o",
    ù: "u",
    ñ: "n",
    Á: "A",
    É: "E",
    Í: "I",
    Ó: "O",
    Ú: "U",
    À: "A",
    È: "E",
    Ì: "I",
    Ò: "O",
    Ù: "U",
    Ñ: "N",
};

export const normalizeText = (text) => {
  return text.replace(/[áàéèíìóòúùñÁÉÍÓÚÀÈÌÒÙÑ]/g, (match) => chars[match] || match);
}

export const getCalendarRange = () => {
  const startDate = dayjs().subtract(1, 'month').startOf('month').toISOString();
  const endDate = dayjs().add(1, 'month').endOf('month').toISOString();

  return [startDate, endDate];
}

export const getComboHorariosData = (horarios) => {
  let array = [];
  horarios.forEach((element) => {
  array.push({ value: element, label: element });
  });
  
  return array;
}

export const getHorariosDisponiblesByCitas = (citas, Horarios) => {
  console.log(citas)
  console.log(Horarios)
  let horarios_disponibles = [...Horarios];

  citas.forEach((cita) => {
    console.log(cita)
    let hora = `${cita.HoraCita.substring(0, 1) === "0"
      ? cita.HoraCita.substring(1, 5)
      : cita.HoraCita.substring(0, 5)
      } ${cita.HoraCita.substring(
        cita.HoraCita.length - 5,
        cita.HoraCita.length
      ) === "a. m."
        ? "a.m"
        : "p.m"
      }`;
      console.log(hora)
      if(horarios_disponibles.includes(hora)){
        console.log('remove: ' + hora)
        const index =horarios_disponibles.indexOf(hora);
        console.log(index)
        horarios_disponibles.splice(index, 1);
        console.log(horarios_disponibles);
      }
  });

  return horarios_disponibles;
}

export const getEdad = (dateString) => {
  let hoy = new Date();
  let fNacimiento = new Date(dateString.replace(/-/g, "/"));
  let edad = hoy.getFullYear() - fNacimiento.getFullYear();
  let diferenciaMeses = hoy.getMonth() - fNacimiento.getMonth();
  if (
    diferenciaMeses < 0 ||
    (diferenciaMeses === 0 && hoy.getDate() < fNacimiento.getDate())
  ) {
    edad--;
  }
  
  return edad;
}

export const getDateFromString = (fechaString, horaString) => {
  const resultDate = dayjs(`${fechaString} ${horaString}`, 'DD-MM-YYYY HH:mm:ss');
  
  let tiempoCita = localStorage.getItem("duracionCita");

  return [
    resultDate.toLocaleString(),
    resultDate.add(parseInt(tiempoCita), 'minutes').toLocaleString()
  ];
}