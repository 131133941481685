import React, { useState } from "react";
import "../comp-styles/Contactocard.scss";

import Iconos from "../IconosDeSitio";
import PositionedMenu from "./menuvertical";
import { Empty } from "antd";

export default function ContactosCard({ openDetalleCont, setOpenDetail, lista, contactDetail, deleteContact, setIdContacto, setInputsEditable, validateInputs, itemsPerPage }) {
  const [actualPage, setActualPage] = useState(0);
  const [itemLastIndex, setItemLastIndex] = useState(itemsPerPage);

  const loadMoreContacts = () =>{
    let newPage = actualPage + 1;
    setActualPage(newPage);

    let newLastIndex = itemLastIndex + (itemsPerPage);
    setItemLastIndex(newLastIndex)
  }

  if(lista.length ===0){
    return(
      <div style={{marginTop:25}}>
        <Empty description={<span style={{ color: "black" }}> No se encontraron pacientes</span>} />
      </div>
    )
  }
  return (
    <>
    {
      lista.map((contacto, index) => {
          if(index<itemLastIndex){
            return(
              <div className="ContenedorPrincipalCard">
                <div
                  className="TarjetaPincipal"
                  onClick={() => {
                    contactDetail(contacto.IdContacto, 1);
                    openDetalleCont(true);
                  }}
                >
                  <div className="ContenedorContacto">
                      <div className="Cardcontactos">
                        <div className="BgrImg">
                          <img id={`img_contacto_card${contacto.IdContacto}`} src={contacto.Imagen_url !== null ? "https://" + contacto.Imagen_url : Iconos[0]} alt=""
                            onError={() => {
                              let img = document.getElementById(`img_contacto_card${contacto.IdContacto}`);
                              img.setAttribute("src", Iconos[0]);
                            }}
                          />
                        </div>
                        <div>
                          <b>{contacto.Nombre + " " + contacto.ApellidoPaterno + " " + contacto.ApellidoMaterno}</b>
                          <div>
                            <span>
                              <b style={{ color: "#148f9f" }}>{contacto.Tipo}&nbsp;</b>
                              {contacto.YgiaUser}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
                <PositionedMenu
                  setIdContacto={setIdContacto}
                  extras={[
                    contacto,
                    setOpenDetail,
                    contactDetail,
                    setInputsEditable,
                    validateInputs,
                    deleteContact
                  ]} />
              </div>
            )
          }
        }
      )
    }
    { (itemLastIndex < lista.length && lista.length >0) &&
      <div style={{display:'flex', alignItems:'center', justifyContent:'center', marginTop:30, marginBottom:50}}>
        <div style={{backgroundColor: '#148F9F', display:'flex', cursor:'pointer', width:150,textAlign:'center|justify', color:'white', alignItems:'center', justifyContent:'center', height:48, borderRadius:90, boxShadow: '2px 2px 10px 0px grey', fontWeight:'bold', fontSize:16
        }} onClick={()=>loadMoreContacts()}><p>Cargar mas...</p></div>
      </div>
    }
    </>
  );
}
