import React from 'react'
import { useNavigate } from "react-router-dom";

import "../comp-styles/ModalAddExpediente.scss"
import Iconos from "../IconosDeSitio"


function ModalAddExpediente({ contactos, isOpen, closeModal, setIdContacto }) {
  // console.log(contactos);
  const navigate = useNavigate();
  return (<>
    {contactos.length>0 && contactos.map((contacto) => (

      <div className="PrinCardv" key={contacto.id} onClick={()=>{
        closeModal(true);
        navigate("/app/NuevoExpediente",
        {
          //  replace: false,
          state: {
            id: contacto.id,
            imagen: contacto.imagen,
            nombre: contacto.nombre,
          },
        });
      }}>
        <div className="Containerv">
          <div className="Cardv">
            <div className="BgrImgv">
              <img src={contacto.imagen != null ? "https://" + contacto.imagen : Iconos[0]} />
               <div style={{ width: "90%" }}>
              <b>{contacto.nombre}</b>
            </div></div>
          </div>
        </div>
      </div>


    ))}
  </>)
}

export default ModalAddExpediente

